import React, { useState, useEffect } from "react";
import "./header.css";
import Container from "react-bootstrap/Container";
import { Navbar, Nav } from 'react-bootstrap';
// import { data } from '../../helpers/Data';
import { Dropdown, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { APP_ENV, GET_SINGLE_REPORT_URL } from "../../config";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Slide from "@mui/material/Slide";
import ReviewPage from "../review-page/ReviewPage";
import api from "../../utils/api";
import config from "../../config/index";
import {  auth, deleteUser, firestore, doc, deleteDoc} from "../../firebase";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Header(props) {

  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedin = localStorage.getItem("isLoggedin");
  // window.location.reload(true);
  function logout() {
    localStorage.clear();
    window.location.replace("#/login");
    window.location.reload(false);
  }

  const deleteAccount = async () => {  
    const currentUser = auth.currentUser;
    // console.log(currentUser.uid);
    
    const userDocRef = doc(firestore, 'users', currentUser.uid);

    const response = api({
      url: '/user/updateDeleteAttribute',
      method: 'PUT',
      params: { phoneNumber: currentUser.phoneNumber },
    });
  
    await deleteDoc(userDocRef);
    await deleteUser(currentUser);    
  
    localStorage.clear();
    window.location.replace("#/login");
    window.location.reload(false);
  };

  const showDeleteConfirmation = () => {
    const isConfirmed = window.confirm('Are you sure you want to delete your account?');

    if (isConfirmed) {
      deleteAccount();
    }
  };

  function isUserClient() {
    return (APP_ENV === "client") ? true : false;
  }

  const isOrderSelected = () => {
    return localStorage.getItem("orderId") ? true : false;
  }

  const isReportReady = () => {
    return (!localStorage.getItem("reportReady") || localStorage.getItem("reportReady") == 0) ? false : true;
  }

  const [allPostName, setAllPostName] = useState(null);

  const getDataName = async () => {
    const data = await api({
      url: `/zoho/allOrders`,
      method: "GET",
      params: { phoneNumber: window.localStorage.phoneNumber }
    })

    const tempPD = data?.data[0];
    setAllPostName(tempPD);
    localStorage.setItem("orderId", tempPD.ID);
    console.log("#Header-data: ", data.data);
  };

  const [draftOrder, setDraftOrder] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSignoffModal, setShowSignoffModal] = useState(false);
  const [queryParameters] = useSearchParams();
  const [signOffValue, setSignOffValue] = useState('');

  const getUrlData = async () => {
    let _orderId = queryParameters.get("orderId") || localStorage.getItem("orderId");
    if (_orderId) {
      const reports = await api({
        url: GET_SINGLE_REPORT_URL,
        method: "POST",
        params: { orderId: _orderId },
      });
      setDraftOrder(reports?.data[0]);
      setSignOffValue(reports?.data[0]['Status'])
      localStorage.setItem("orderId", _orderId);
    }
  }
  
  const updateOrderStatus = async (signOffStatus) => {
    let _orderId = queryParameters.get("orderId") || localStorage.getItem("orderId");
    if (_orderId && signOffStatus) {
      const report = await api({
        url: `/zoho/order/ghi/${_orderId}`,
        method: "PUT",
        data: { status: signOffStatus }
      });
    }
  }

  useEffect(() => {
    if (!isUserClient()) {
      return;
    }

    if (isLoggedin && !allPostName) {
      getDataName();
    }
  }, [isLoggedin]);

  useEffect(() => {
    if (isUserClient()) {
      return;
    }

    getUrlData();
    setShowModal(false);
    setShowSignoffModal(false);

  }, [location])


  return (
    <header className="header topnav" id="myTopnav">
      <Navbar collapseOnSelect fixed="top" expand="lg">
        <Container fluid>
          <Navbar.Brand
            href={
              localStorage.getItem("isLoggedin") === "true"
                ? "#/dashboard" + location.search
                : "#/home"
            }
            className="nav-brand-img"
          >
            <img src="/assets/images/StrandLogo.svg" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="nav-items ml-auto">
            {isUserClient() && localStorage.getItem("isLoggedin") === "true" &&
              <Nav.Link href="#/contact" className="m-2 head-txt">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    className="bi bi-envelope-open me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                  </svg>
                </span>
                Contact
              </Nav.Link>
            }
            <Nav.Link href="#/faq" className="m-2 head-txt">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="currentColor"
                  className="bi bi-patch-question me-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z" />
                  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                  <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                </svg>
              </span>
              FAQs
            </Nav.Link>
            {localStorage.getItem("isLoggedin") === "true" && isOrderSelected() && isReportReady() && (
              <Nav.Link href={"#/download" + location.search} className="m-2 head-txt">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    className="bi bi-cloud-arrow-down me-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                    />
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                </span>
                Download My GHI
              </Nav.Link>
            )}
            {!isUserClient() && isLoggedin &&
              <Nav.Link className="m-2 head-txt" onClick={() => setShowModal(!showModal)} href="#" >
                {draftOrder?.Order_ID?.display_value ? draftOrder?.Order_ID?.display_value : "View as"}
                <ExpandMore />
              </Nav.Link>
            }

            {isUserClient() && localStorage.getItem("isLoggedin") === "true" &&
              <Nav.Link className="m-2 menuHighlight2 head-txt" href="#/consult">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="#fff"
                    className="bi bi-telephone-outbound me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </span>
                Request Consultation
              </Nav.Link>
            }
            {/* {isUserClient() && localStorage.getItem("isLoggedin") === "true" && isOrderSelected() && (
              <Nav.Link
                onClick={() => { navigate("/dashboard" + location.search) }}
                className="m-2 menuHighlight head-txt"
              >
                Home
              </Nav.Link>
            )} */}

            {!isUserClient() && localStorage.getItem("isLoggedin") === "true" && isOrderSelected() && (
              <Nav.Link
                onClick={() => { setShowSignoffModal(!showSignoffModal) }}
                className="m-2 menuHighlight head-txt"
              >
                Sign-off
                <ExpandMore />
              </Nav.Link>
            )}



            {!isLoggedin && (
              <Nav.Link href="#/login" className="m-3 head-txt">
                Login
              </Nav.Link>
            )}
            {localStorage.getItem("isLoggedin") === "true" && (
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="btn6 me-3 head-txt"
                >
                  {isUserClient() ? allPostName?.Name?.display_value : "Super Admin"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="drop-down">
                  {/* <Dropdown.Item
                    href=""
                    className="head-txt"
                    onClick={handleClickOpen}
                  >
                    Change Number
                  </Dropdown.Item>
                  <Dropdown.Item href="#/MyAccount" className="head-txt">
                    Account
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item href="#/user">Switch Profile</Dropdown.Item> */}
                  <Dropdown.Item className="head-txt" onClick={() => logout()}>
                    Logout
                  </Dropdown.Item>
                  <Dropdown.Item className="head-txt" onClick={showDeleteConfirmation}>
                    Delete Account
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Navbar.Collapse>
        </Container>

      </Navbar>

      <Modal show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">Select Strand Id for Viewing</Modal.Title>
        </Modal.Header>
        <Modal.Body
        >
          <ReviewPage />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={showSignoffModal}
        onHide={() => setShowSignoffModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">Sign-off</Modal.Title>
        </Modal.Header>
        <Modal.Body
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" name="flexRadioDefault" 
                    id="flexRadioDefault1" 
                    value="Draft" 
                    onChange={(e) => setSignOffValue(e.target.value)}
                    checked={signOffValue == "Draft"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Draft
                  </label>
                </div>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" name="flexRadioDefault" 
                    id="flexRadioDefault1" 
                    value="Draft/Bioinformatics" 
                    onChange={(e) => setSignOffValue(e.target.value)}
                    checked={signOffValue == "Draft/Bioinformatics"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Assign Draft to Bioinformatics Team
                  </label>
                </div>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" name="flexRadioDefault" 
                    id="flexRadioDefault1" 
                    value="Draft/Interpretation" 
                    onChange={(e) => setSignOffValue(e.target.value)}
                    checked={signOffValue == "Draft/Interpretation"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Assign Draft to Interpretation Team
                  </label>
                </div>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" name="flexRadioDefault" 
                    id="flexRadioDefault1" 
                    value="Draft/Software" 
                    onChange={(e) => setSignOffValue(e.target.value)}
                    checked={signOffValue == "Draft/Software"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Assign Draft to Software Team
                  </label>
                </div>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="radio" name="flexRadioDefault" 
                    id="flexRadioDefault1" 
                    value="Ready" 
                    onChange={(e) => setSignOffValue(e.target.value)}
                    checked={signOffValue == "Ready"}
                  />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Approved
                  </label>
                </div>
                {/* <label className="form-label mt-5">Comment</label>
                <textarea className="form-control" rows="5" placeholder="Enter your sign-off message here"></textarea> */}
              </div>
            </div>
          </div>




        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowSignoffModal(false)}>Close</Button> */}
          <Nav.Link className="m-2 p-2 btn btn-light" onClick={() => setShowSignoffModal(false)}>Cancel</Nav.Link>
          <Nav.Link className="m-2 p-2 btn btn-warning" onClick={() => {setShowSignoffModal(false); updateOrderStatus(signOffValue)}}>Submit</Nav.Link>


        </Modal.Footer>
      </Modal>

      {/* modal */}
    </header>
  );
}
