import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes, HashRouter } from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import App from './App';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

     <HashRouter> 
    <ScrollToTop /> 
    <App />
      </HashRouter> 
    
);
