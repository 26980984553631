import React, { useState } from 'react';
import { Row, Modal } from "react-bootstrap";
import NotesAndLimitations from "../notes-and-limitations/NotesAndLimitations";
import Button from "@mui/material/Button";
import ReportUtils from "../../utils/ReportUtils";
import { Link } from "react-router-dom";


export default function NotesAndLimitationsModal(props) {
    const curationData = props.curationData;
    const draftOrder = props.draftOrder;
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            {ReportUtils.isVariantNotConfirmed(curationData, draftOrder) &&
                <Row>
                    <Link style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#aa0000",
                    }} onClick={() => setShowModal(true)}>See notes and limitations</Link>
                </Row>
            }
            <Modal
                backdrop="true"
                keyboard={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="h6">Notes and Limitations</Modal.Title>
                </Modal.Header>
                <Modal.Body
                >
                    <NotesAndLimitations draftOrder={draftOrder} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
