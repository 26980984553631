import React from "react";
import "./home.css";
import "../chatbot/Review";
import Chatbot from "react-chatbot-kit";

export default function Home(props) {
  props.onLoad(false);

  function onViewInsightClick(e) {
    e.preventDefault();
    if (localStorage.getItem("isLoggedin") == "true") {
      window.location.href = "#/dashboard";
    } else {
      window.location.href = "#/login";
    }
  }

  return (
    <div className="pageBg">
      <div className="pageBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img
                className="img-fluid bannerLeftImage p-4 d-none d-md-block mx-auto"
                src="/assets/images/BloodTest-bro.png"
              ></img>
              <img
                className="img-fluid bannerLeftImage p-4 d-md-none mx-auto"
                src="/assets/images/Mobile/BloodTest-bro.png"
              ></img>
            </div>

            <div className="col-md-6">
              <h2 className="ff1 c1 fs34 text-center text-md-start">
                {" "}
                Your genes sequenced here, for posterity
              </h2>
              <p className="fs16 text-center text-md-start px-3 px-md-0 mt-3 pe-md-5">
                <li>~20,000 genes sequenced</li>
                <li>Genomic risk reports for various diseases</li>
                <li>Carrier status for mutations that might affect your children
                </li>
                <li>Your genomic predisposition to handling common drugs</li>
                <li>Ongoing updates on the latest scientific progress</li>
              </p>
              <div className="text-center text-md-start mt-3">
                <button className="btn btn1" onClick={onViewInsightClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-heart me-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                  </svg>
                  View Insights
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
