import React, { useState, useEffect } from "react";
import "./Cardio.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GET_SINGLE_REPORT_URL } from "../../config/index";
import GradingIcon from '@mui/icons-material/Grading';
import api from "../../utils/api";

export default function Cardio(props) {
  props.onLoad(true);
  const naviage = useNavigate();
  const location = useLocation();



  const [allPost, setAllPost] = useState([]);
  const [orderData, setOrder] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [allData, setAllData] = useState([]);
  const getData = async () => {
    let res = await api.get("/adultOnset/allCardio");
    // let res = await result.json();
    setAllPost(res?.data?.data);
    console.log("cardio", res);
  };

  useEffect(
    () => {
      getData();
    },
    []
    //
  );

  const [draftOrder, setDraftOrder] = useState(null);
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderIds = queryParameters.get("orderId")
    setOrderId(orderIds)
    console.log("carrierOrderId", orderIds)
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });
    console.log("new log carpo", reports);
    setDraftOrder(reports?.data[0]);
    console.log("draftOrderscarpo", draftOrder)
  }
  function onVeiwReportClick() {
    naviage(`/cardio-report?orderId=${orderId}`);
  }


  return (
    <div className="innerWrapper container-fluid">
      <Row className="justify-content-between cancer-bread">
        <Col className="pt-2 bread-wid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadForMobile">
              <li className="breadcrumb-item">
                <a href={"#/dashboard" + location.search}>Dashboard</a>
              </li>
              <li className="breadcrumb-item marginTop">
                <a href={"#/dashboard" + location.search}>Adult Onset</a>
              </li>
              <li
                className="breadcrumb-item active bg1 marginTop"
                aria-current="page"
              >
                Cardio
              </li>
            </ol>
          </nav>
        </Col>
        <Col className="btncol">
          <button
            disabled={!localStorage.getItem('reportReady') || localStorage.getItem('reportReady') == 0}
            className="btn7 float-end"
            onClick={onVeiwReportClick}
          ><GradingIcon />View Results
          </button>
        </Col>
        {/* start repo sec */}
      </Row>
      {allPost.map((item) => {
        return (
          <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
            <div className="d-flex flex-column body-para">
              <Row>
                <Col>
                  <div style={{ display: "flex", marginTop: "10px" }}>

                    <h2 className="ff1 fs30 fw600 mt-3 text-secondary">{item.section1_title}</h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc1,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc2,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc3,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc4,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc5,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc6,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc7,
                    }}
                  />
                </Col>
              </Row>
            </div>

            {/* carousel -1 */}

            {/* <div className="p-2"> */}

            <Row>
              <Col>
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: item.section2_description,
                  }}
                /> */}
                <p className="fs18"></p>
              </Col>
            </Row>

            <Row>
              <img
                src="/assets/images/Cardio -Types-New.png"
                className="img-fluid"
              ></img>
            </Row>
            <Row>
              <Col>
                <div style={{ display: "flex", marginTop: "30px" }}>

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">Testing for Risk</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <p className="fs18">
                  A number of genes are known to carry variants that cause a
                  substantially increased risk on the above conditions. Our
                  test sequences these genes to identify if you have a risk
                  causing mutation. Medical guidelines like those of the
                  American College of Cardiology (ACC)/American Heart
                  Association (AHA) today suggest testing those who have a
                  family history of certain cardiac conditions or are
                  symptomatic. However, while data is still emerging, it is
                  likely that many who are at risk are missed by these
                  guidelines. Studies on sudden unexplained deaths in
                  apparently healthy individuals (cause of death
                  undetermined after comprehensive forensic investigation)
                  have shown that 13% of such individuals have genomic
                  variants that cause arrhythmias. Another very recent study
                  shows that 15% of such deaths have genomic variants that
                  cause cardiomyopathies even though the heart has no
                  structural changes at the time of death. More data is
                  likely to emerge in the near future.
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <div style={{ display: "flex" }}>

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">If You Are Positive</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <p className="fs18">
                  You should work closely with your cardiologist to further
                  confirm, assess, and manage your risk.
                </p>
              </Col>
            </Row>
            <div>
              <Row>
                <Col>
                  <div style={{ display: "flex" }}>

                    <h2 className="ff1 fs30 fw600 mt-3 text-secondary">If You Are Negative</h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p className="fs18 text-align">
                    Based on our current knowledge, you are not at increased
                    risk from the known genomic causes covered by this test.
                    However, scientific knowledge is advancing rapidly so please
                    watch your account for any updates as more information
                    becomes available. In addition, note that some of the common
                    causes of cardiovascular disease are not so clearly caused
                    by a few genetic variants and you will still need to follow
                    practices recommended by your doctor to safeguard the health
                    of your heart.
                  </p>
                </Col>
              </Row>
            </div>

            <Row>
              <Col className="d-flex mt-4">

                <h2 className="ff1 fs30 fw600 mt-3 text-secondary">{item?.section3_title}</h2>
              </Col>
            </Row>

            <div className="card mb-3 bdr-rad16 bxShdow bdrNone p-5">
              <Row className="align-items-center">
                <Carousel>
                  <Carousel.Item>
                    <Row className="align-items-center">
                      <Col xs={12} md={6}>
                        <div className="p-md-5 carousel-cap">
                          <h3>Familial Atrial Fibrillation</h3>
                          <p>
                            Genes Involved:{" "}
                            <span className="fst-italic">
                              BCC9, EMD*, GJAS*, KCNA5, KCNE2*, KCNE3, KCNH2*, KCNJ2, KCNJ5,
                              KCNQ1*, LMNA*, NPPA, NUP155, SCN1B, SCN2B, SCN3B, SCN5A*
                            </span>
                          </p>
                          <p>
                            Familial atrial fibrillation is an inherited abnormality of the
                            heart's normal rhythm. Atrial fibrillation is characterized by
                            episodes of uncoordinated electrical activity (fibrillation) in
                            the heart's upper chambers (the atria), which cause a fast and
                            irregular heartbeat.
                          </p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: "80px" }}>
                          <img
                            src="/assets/images/Familial-Atrial-Fibrillation2.png"
                            alt="Second slide"
                            className="img-fluid"
                            width={500}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center text-center">
                      <Col className="pt-5"></Col>
                    </Row>
                  </Carousel.Item>

                  <Carousel.Item>
                    <Row className="align-items-center">
                      <Col xs={12} md={6}>
                        <div className="p-md-5 carousel-cap">
                          <h3>Hypertrophic Cardiomyopathy</h3>
                          <p>
                            Genes Involved:{" "}
                            <span className="fst-italic">
                              ACE, ACTC1, ACTN2*, AGT, ANKRD1, CASQ2, COX15, CSRP3, DES*,
                              FXN*, GLA, LAMP2, MYBPC3, MYH6, MYH7, MYL2, MYL3, MYLK2,
                              MYO6, MYOM1, MYOZ2, NDUFAF1*, NEXN, OBSCN*, PDLIM3, PLN,
                              PRKAG2*, RAF1, SLC25A4, SRI, TCAP, TNNC1, TNNI3, TNNT2, TPM1,
                              TTN, TTR
                            </span>
                          </p>
                          <p>
                            Hypertrophic Cardiomyopathy (HCM) is a disease in which the
                            heart muscle becomes thickened (hypertrophied). The thickened
                            heart muscle can make it harder for the heart to pump blood.
                            Hypertrophic cardiomyopathy often goes undiagnosed because many
                            people with the disease have few, if any, symptoms.
                          </p>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: "140px" }}>
                          <img
                            src="/assets/images/Hypertrophic-Cardiomyopathy.png"
                            alt="Second slide"
                            className="img-fluid"
                            width={700}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row></Row>
                  </Carousel.Item>
                </Carousel>
              </Row>
            </div>


            <Row>
              <Col className="d-flex mt-4">
                {/* <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#e14d45"
                    className="bi bi-fingerprint"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                    <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                    <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                    <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                    <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                  </svg>
                </span> */}
                <h2 className="ff1 fs30 fw600 mt-3 text-secondary">
                  Celebrities with a Genetic Cardiac Condition
                </h2>
              </Col>
            </Row>

            <div className="text-align">
              <Row>
                <Col>
                  <p className="fs18 ">
                    <ul>
                      <li>Elvis Presley, died at 42</li>
                      <li>Jared Butler</li>
                    </ul>
                  </p>
                  <div>
                    <h3 className="mt-1">Reading Material</h3>
                    <ol>
                      <li>
                        <a href="http://thehealthsite.com/news/elvis-presley-had-a-genetic-heart-condition-139145/" target="_blank">
                          http://thehealthsite.com/news/elvis-presley-had-a-genetic-heart-condition-139145/
                        </a>
                      </li>

                      <li>
                        <a href="https://marfan.org/2014/06/22/the-marfan-foundation-lauds-nba-for-screening-that-detects-marfan-syndrome-a-life-threatening-condition-in-potential-first-round-draft-pick/" target="_blank">
                          https://marfan.org/2014/06/22/the-marfan-foundation-lauds-nba-for-screening-that-detects-marfan-syndrome-a-life-threatening-condition-in-potential-first-round-draft-pick/
                        </a>
                      </li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </div>
  );
}
