import React, { useState } from 'react';
import './Sidebar.css';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState(
    location.pathname.replace('/', '')
  );
  if (selectedMenu == 'home') {
    setSelectedMenu('adult-onset', 'carrier-risk', 'pharmacogenomics', 'cancer', 'cardio', 'metabolism');
  }
  function onMenuClick(clickedMenuName) {
    setSelectedMenu(clickedMenuName);
    console.log(clickedMenuName)
    if (clickedMenuName == "adult-onset") {
      setMenuOpen(!menuOpen)
    }

  }

  function onChildMenuClick(location) {
    navigate(location);
    setMenuOpen(false)
  }

  // document.addEventListener('mouseup', function (e) {
  //   var container = document.getElementById('slideSubMenu');
  //   if (!container.contains(e.target)) {
  //     container.style.display = 'none';
  //   }
  // });

  return (
    <>
      <div className="sideBar">
        <div className="sidebarInner">
          <h2 className="sidebarHero c1">Quick Links</h2>

          <nav className="sideBarNav">
            {/* One */}
            <ul >
              <div className=''>
                <li
                  className="position-relative subMenu blank-img1"
                  onClick={() => onMenuClick('adult-onset')}
                  name="adult-onset"
                  style={{
                    backgroundColor:
                      selectedMenu == 'adult-onset' ? '#EDA423' : '#313541',

                  }}
                >
                  <a
                    onClick={() => setMenuOpen(true)}
                    //  href="#/adult-onset"
                    className="nav-link"
                  >
                    <div className=" navIcon adult-icon">
                      <img src="/adult-onset.png" style={{ height: "30px" }} className='imag-fluids' />
                    </div>
                    <div className="navLabel">Adult Onset</div>
                  </a>

                  {menuOpen && (
                    <ul className="slideSubMenu" id="slideSubMenu">
                      <li className='sideSubHover hover1'
                        name="cancer" style={{
                          backgroundColor:
                            selectedMenu == 'cardio' ? '#EDA423' : '#313541'
                        }}>
                        <a
                          onClick={() => onChildMenuClick('cancer' + location.search)}
                          className="nav-link"
                        >
                          <div className="navIcon  ">
                            <img src="/Cancer-icon-white.png" />
                          </div>
                          <div className="navLabel">Cancer</div>
                        </a>
                      </li>
                      <li className='sideSubHover hover1'
                        name="cardio"
                        style={{
                          backgroundColor:
                            selectedMenu == 'cardio' ? '#EDA423' : '#313541'
                        }}>
                        <a
                          onClick={() => onChildMenuClick('cardio' + location.search)}
                          className="nav-link"
                        >
                          <div className="navIcon">
                            <img src="/cardio-white.png" style={{ width: "33px", height: "40px" }}
                            />
                          </div>
                          <div className="navLabel">Cardio</div>
                        </a>
                      </li>
                      <li className='sideSubHover hover1'
                        name="metabolic"
                        style={{
                          backgroundColor:
                            selectedMenu == 'metabolic' ? '#EDA423' : '#313541'
                        }}>
                        <a
                          onClick={() => onChildMenuClick('metabolism' + location.search)}
                          className="nav-link"
                        >
                          <div className="navIcon ">
                            <img src="/metabolism-white.png" style={{ width: "35px", height: "35px" }} />
                          </div>
                          <div className="navLabel ">Metabolic</div>
                        </a>
                      </li>
                      <li style={{ backgroundColor: "#313541" }}>
                        <a onClick={() => setMenuOpen(false)} className="nav-link">
                          <div className="navLabel close" >Close</div>
                        </a>
                      </li>
                    </ul>
                  )}
                </li></div>
            </ul>

            <ul>
              <li
                className="carrier-risk sideHover blank-img1"
                onClick={() => onMenuClick('carrier-risk')}
                style={{
                  'backgroundColor':
                    selectedMenu == 'carrier-risk' ? '#EDA423' : '#313541',
                }}
              >
                <a onClick={() => onChildMenuClick('crrierrisk' + location.search)}>
                  <div className="navIcon zoom">
                    <img src="/carrier-risk-white.png" style={{ width: "35px", height: "32px" }} className='img-fluid' />
                  </div>
                  <div className="navLabel">Carrier Risk</div>
                </a>
              </li>
            </ul>

            <ul>
              <li
                className="pharmacogenomics sideHover blank-img1"
                onClick={() => onMenuClick('pharmacogenomics')}
                style={{
                  'backgroundColor':
                    selectedMenu == 'pharmacogenomics' ? '#EDA423' : '#313541',
                }}
              >
                <a onClick={() => onChildMenuClick('pharmacogenomics' + location.search)}>
                  <div className="navIcon zoom">
                    <img src="/pharma-white.png" style={{ width: "35px", height: "35px" }} className='img-fluid' />
                  </div>
                  <div className="navLabel pharmahead">Pharmacogenomics</div>
                </a>
              </li>
              <li className='bdrNone'>
                <a href="#/whatsnew">
                  <div className="navLabel version">v.0.1</div>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>


      {/* MOBILE */}

      {/* <nav className="footerMobileNav"> */}
      {/* One */}
      {/* <ul>
            <li
              className="position-relative subMenu"
              // onClick={() => onMenuClick('adult-onset')}
              name="adult-onset"
              style={{
                backgroundColor:
                  selectedMenu == 'adult-onset' ? '#EDA423' : '#FFFFFF',
              }}
            >
              <a
                onClick={() => setMenuOpen(true)}
                //  href="#/adult-onset"
                className="nav-link"
              >
                <div className="navIcon zoom">
                  <img src="/sidebar3.png" />
                </div>
                <div className="navLabel">Adult Onset</div>
              </a>              
            </li>
          

        
            <li
              name="carrier-risk"
              onClick={() => onMenuClick('crrier-risk')}
              style={{
                'backgroundColor':
                  selectedMenu == 'carrier-risk' ? '#F5F4F4' : '#FFFFFF',
              }}
            >
              <a href="#/crrierrisk">
                <div className="navIcon">
                  <img src="https://github.com/nehasaipangallu/strand2/blob/main/public/spreading%201.svg?raw=true" />
                </div>
                <div className="navLabel">Carrier Risk</div>
              </a>
            </li>        

         
            <li
              name="pharmacogenomics"
              onClick={() => onMenuClick('pharmacogenomics')}
              style={{
                'backgroundColor':
                  selectedMenu == 'pharmacogenomics' ? '#F5F4F4' : '#FFFFFF',
              }}
            >
              <a href="#/pharmacogenomics">
                <div className="navIcon">
                  <img src="/sidebar2.png" />
                </div>
                <div className="navLabel">Pharmacogenomics</div>
              </a>
            </li>
            <li>
                            
                <div className="navLabel">v.0.8.3.2</div>
            
            </li>
            </ul> */}

      {/* </nav> */}
    </>
  );
}
