import React, { useState, useEffect } from 'react';
import './Pop.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PopService from '../../services/PopService';


export default function Pop(props) {
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState({});

  useEffect(() => {
    PopService.on('openPop', ({ component, props }) => {
   
      console.log(props, component);
      setShow(true);
      setModal({
        props,
        component,
        close: (value) => {
          setModal({});
        },
        });
       });
  }, []
  );
  
  function hidePopup() {
    setShow(false);
  }
  return (
    <Modal
      show={show}
      onHide={hidePopup}
      backdrop="true"
      keyboard={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton onClick={() => setShow(false)}>
        <Modal.Title>{modal?.component}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <Table responsive="sm" className="report-pop-up-table">
            <tbody>
              <tr>
                <td>Gene</td>
                <td>{modal.props?.data?.gene_symbol}</td>
              </tr>
              <tr>
                <td>Variant Type</td>
                <td>{Object.keys(modal.props?.data?.variant_description || {})?.map((key) => modal.props?.data?.variant_description[key])}</td>
              </tr>
              <tr>
                <td>Zygosity</td>
                <td>{modal.props?.data?.zygosity}</td>
              </tr>
              <tr>
                <td>Inheritance</td>
                <td>{modal.props?.data?.inheritance}</td>
              </tr>
              <tr>
                <td>Variant Class</td>
                <td>{modal.props?.data?.variant_classification}</td>
              </tr>
            </tbody>
          </Table>

        
          <></>
          {/* <Table responsive="sm" className="report-pop-up-table">
            <tbody>
              <tr>
                <td>Gene</td>
                <td>{data?.report?.findings[0]?.detailed_findings[1]?.biomarkers?.gene_symbol}</td>
              </tr>
              <tr>
                <td>Variant Type</td>
                <td>{data?.report?.findings[0]?.detailed_findings[0].biomarkers?.variant_description?.genomic_hgvs},{data?.report?.findings[0]?.detailed_findings[0].biomarkers?.variant_description?.cdna_hgvs},{data?.report?.findings[0]?.detailed_findings[0].biomarkers?.variant_description?.protein_hgvs}</td>
              </tr>
              <tr>
                <td>Zygosity</td>
                <td>{data?.report?.findings[0]?.detailed_findings[0]?.biomarkers?.zygosity}</td>
              </tr>
              <tr>
                <td>Inheritance</td>
                <td>{data?.report?.findings[0]?.detailed_findings[0].biomarkers?.inheritance}</td>
              </tr>
              <tr>
                <td>Variant Class</td>
                <td>{data?.report?.findings[0]?.detailed_findings[0].biomarkers?.variant_classification}</td>
              </tr>
            </tbody>
          </Table>
          <Modal.Title>Cardio-report</Modal.Title>
          <Table responsive="sm" className="report-pop-up-table">
            <tbody>
              <tr>
                <td>Gene</td>
                <td></td>
              </tr>
              <tr>
                <td>Variant Type</td>
                <td></td>
              </tr>
              <tr>
                <td>Zygosity</td>
                <td></td>
              </tr>
              <tr>
                <td>Inheritance</td>
                <td></td>
              </tr>
              <tr>
                <td>Variant Class</td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <Modal.Title>Metabolic-report</Modal.Title>
          <Table responsive="sm" className="report-pop-up-table">
            <tbody>
              <tr>
                <td>Gene</td>
                <td>{data?.report?.findings[2]?.detailed_findings[0]?.biomarkers?.gene_symbol}</td>
              </tr>
              <tr>
                <td>Variant Type</td>
                <td>{data?.report?.findings[2]?.detailed_findings[0].biomarkers?.variant_description?.genomic_hgvs},{data?.report?.findings[2]?.detailed_findings[0].biomarkers?.variant_description?.cdna_hgvs}</td>
              </tr>
              <tr>
                <td>Zygosity</td>
                <td>{data?.report?.findings[2]?.detailed_findings[0]?.biomarkers?.zygosity}</td>
              </tr>
              <tr>
                <td>Inheritance</td>
                <td>{data?.report?.findings[2]?.detailed_findings[0].biomarkers?.inheritance}</td>
              </tr>
              <tr>
                <td>Variant Class</td>
                <td>{data?.report?.findings[2]?.detailed_findings[0].biomarkers?.variant_classification}</td>
              </tr>
            </tbody>
          </Table>
           */}
              <p className="m-3">
            <strong>Gene</strong>: Genes carry recipes for the creation of molecules that keep the body running. There are ~20,000 genes in the human body.
          </p>
          <p className="m-3">
            <strong>Variant</strong>: This refers to a character in the gene where you are different from the “average human”.

          </p>
          <p className="m-3">
            <strong>Zygosity</strong>: We inherit 3 billion genomic characters from each parent. A variant is Homozygous if it is found in the characters inherited from BOTH parents and Heterozygous if it is found in the characters inherited from only one parent.

          </p>
          <p className="m-3">
            <strong>Variant Classification</strong>: Not all variants have a significant impact on health. We evaluate current scientific evidence to classify variants accordingly as per guidelines of the American College of Medical Genetics. A Pathogenic variant has conclusive scientific evidence, while a Likely Pathogenic variant has enough evidence that stops just short of being conclusive. Variants that don’t have enough evidence or variants known not to have health impact are not reported.

          </p>
          
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hidePopup}>
          Close
        </Button>
        {/* <Button variant="primary" onClick={hidePopup}>
          Save changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}
