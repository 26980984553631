import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Thankyou.css';
import { useNavigate } from 'react-router-dom';

export default function Thankyou(props) {
    props.onLoad(false);
    const navigate = useNavigate();
    const naviagteToHome = () => {
      navigate("/dashboard")
    }
    const navigateToFaq = () => {
        navigate("/faq")
      }

    return (
        <div className="text-center">

            <img src="/thank.svg" className="img-fluid thank "></img>
            <div>
            <h6 style={{marginTop:"-30px", color:"#000000", fontSize:"30px", fontWeight:"bold"}}>Thank You!</h6>
            <p className="mt-3 text-dark">Your message has been received, <br />
                we will update you shortly.</p>
                </div>
            <div>
                <button className=" btn-home " onClick={naviagteToHome} >Back To Home</button>


                <button className="btn-home2 ms-3" onClick={navigateToFaq}>FAQ</button>

            </div>
        </div>
    );
}