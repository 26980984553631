import React, { useState, useEffect } from "react";
// import "./Carrepo.css";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PopService from "../../services/PopService";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useRef } from "react";
import ModalService from "../../services/ModalService";
import { GET_SINGLE_REPORT_URL } from "../../config";
import ReportUtils from "../../utils/ReportUtils";
import VariantStatus from "../variant-status/variant-status";
import DownloadIcon from "@mui/icons-material/Download";
import api from "../../utils/api";
import curation from "../../utils/curation";
import { ENV } from "../../config/index";
import NotesAndLimitationsModal from "../notes-and-limitations-modal/NotesAndLimitationsModal";


export default function CardioReport(props) {
  const location = useLocation();
  const [draftOrder, setDraftOrder] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [draftOrderPdf, setdraftOrderPdf] = useState([]);


  const [cardioFindings, setCardioFindings] = useState(null);
  const [patientGender, setPatientGender] = useState(null);
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [inheritance_Values, setInheritanceValues] = useState(new Set());
  const [error, setError] = useState(null);

  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderId = queryParameters.get("orderId");
    setOrderId(orderId);
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });

    setDraftOrder(reports?.data[0]);
    setPatientGender(reports?.data[0]?.Report_JSON?.report?.demographics?.gender);
    setCardioFindings(reports?.data[0]?.Report_JSON?.report?.
      findings?.find((finding) => finding?.category === "Hereditary Cardio Report"));
  }


  const inheritanceValues = new Set()

  useEffect(() => {
    // console.log("useEffect 2");
    if (ENV === 'development') {
      fetchData();
    }

  }, [inheritance_Values]);

  const fetchData = async () => {
    // console.log("fetchdata");
    try {
      const promises = Array.from(inheritance_Values).map(async (inheritance) => {
        // console.log(`Fetching data for inheritance: ${inheritance}`);
        const response = await api({
          url: '/summary/SummaryData',
          method: 'GET',
          params: { type: inheritance === 'NEGATIVE' ? 'All panels' : 'Cardio', bucket: inheritance },
        });

        // console.log(`Response for ${inheritance}:`, response.data.data);

        return response.data.data;
      });

      const data = await Promise.all(promises);

      setSummaryDetails(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // console.log("useEffect 3");
    (cardioFindings?.summary_findings || cardioFindings?.detailed_findings)?.map((finding) => {
      const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
      if (isVariantDetected) {
        inheritanceValues.add(finding?.biomarkers?.inheritance);
      }
    })
    // console.log("setInheritanceValues", inheritanceValues);
    // inheritanceValues.add("AD");
    // inheritanceValues.add("AD, AR");

    if (inheritanceValues.size === 0) {
      inheritanceValues.add("NEGATIVE");
    }
    setInheritanceValues(inheritanceValues);
  }, [cardioFindings])

  let preDisorderType = '';

  const getDisorderType = (disorderType) => {
    if (disorderType === preDisorderType) return '';
    preDisorderType = disorderType;
    return preDisorderType;
  }

  // const cardioTypes = [
  //   {
  //     value: "hypertrophicCardiomyopathy",
  //     title: "Hypertrophic cardiomyopathy (non syndromic)",
  //     associatedGene: [
  //       "ACTC1",
  //       "ACTN2",
  //       "ANKRD1",
  //       "CALR3",
  //       "CAV3",
  //       "CSRP3",
  //       "FHOD3",
  //       "FLNC",
  //       "JPH2",
  //       "KLF10",
  //       "LDB3",
  //       "MYBPC3",
  //       "MYH6",
  //       "MYH7",
  //       "MYL2",
  //       "MYL3",
  //       "MYLK2",
  //       "MYOM1",
  //       "MYOZ2",
  //       "MYPN",
  //       "NEXN",
  //       "PDLIM3",
  //       "PLN",
  //       "PRKAG2",
  //       "RYR2",
  //       "TCAP",
  //       "TNNC1",
  //       "TNNI3",
  //       "TNNT2",
  //       "TPM1",
  //       "TTN",
  //       "VCL",
  //     ],
  //     icard:
  //       "Hypertrophic cardiomyopathy (HCM) is a disease in which the heart muscle becomes thickened (hypertrophied).",
  //     popupFunction: function openModal10(event) {
  //       ModalService.open(
  //         "Hypertrophic cardiomyopathy",
  //         " Hypertrophic cardiomyopathy (HCM) is a disease in which the heart muscle becomes thickened (hypertrophied). The thickened heart muscle can make it harder for the heart to pump blood. Hypertrophic cardiomyopathy often goes undiagnosed because many people with the disease have few, if any, symptoms."
  //       );
  //     },
  //   },
  //   {
  //     value: "dilatedCardiomyopathy",
  //     title: "Dilated cardiomyopathy (non syndromic)",
  //     associatedGene: [
  //       "ACTN2",
  //       "ABCC9",
  //       "ACTC1",
  //       "ANKRD1",
  //       "BAG3",
  //       "CRYAB",
  //       "CSRP3",
  //       "CTF1",
  //       "DES",
  //       "DMD",
  //       "DSG2",
  //       "DSP",
  //       "DTNA",
  //       "EYA4",
  //       "FKTN",
  //       "FLNC",
  //       "GATAD1",
  //       "ILK",
  //       "JPH2",
  //       "LAMA4",
  //       "LDB3",
  //       "LMNA",
  //       "MYBPC3",
  //       "MYH6",
  //       "MYH7",
  //       "MYL2",
  //       "MYPN",
  //       "NEBL",
  //       "NEXN",
  //       "NKX2-5",
  //       "PLN",
  //       "PRDM16",
  //       "PSEN1",
  //       "PSEN2",
  //       "RAF1",
  //       "RBM20",
  //       "SCN5A",
  //       "SDHA",
  //       "SGCD",
  //       "TCAP",
  //       "TNNC1",
  //       "TNNI3",
  //       "TNNI3K",
  //       "TNNT2",
  //       "TPM1",
  //       "TTN",
  //       "VCL",
  //     ],
  //     icard:
  //       "Familial dilated cardiomyopathy is a genetic form of heart disease. It occurs when heart (cardiac) muscle becomes thin and weakened in at least one chamber of the heart, causing the open area of the chamber to become enlarged. ",
  //     popupFunction: function openModal20(event) {
  //       ModalService.open(
  //         "Dilated cardiomyopathy",
  //         "Familial dilated cardiomyopathy is a genetic form of heart disease. It occurs when heart (cardiac) muscle becomes thin and weakened in at least one chamber of the heart, causing the open area of the chamber to become enlarged (dilated). As a result, the heart is unable to pump blood as efficiently as usual. To compensate, the heart attempts to increase the amount of blood being pumped through the heart, leading to further thinning and weakening of the cardiac muscle. Over time, this condition results in heart failure."
  //       );
  //     },
  //   },
  //   {
  //     value: "Arrhythmogenicright",
  //     title: "Arrhythmogenic right ventricular cardiomyopathy",
  //     associatedGene: [
  //       "CDH2",
  //       "CTNNA3",
  //       "DES",
  //       "DSC2",
  //       "DSG2",
  //       "DSP",
  //       "JUP",
  //       "LMNA",
  //       "PKP2",
  //       "RYR2",
  //       "TGFB3",
  //       "TMEM43",
  //       "TTN",
  //     ],
  //     icard:
  //       "Arrhythmogenic right ventricular cardiomyopathy (ARVC) is a form of heart disease that usually appears in adulthood",
  //     popupFunction: function openModal40(event) {
  //       ModalService.open(
  //         "Arrhythmogenic right ventricular dystrophy",
  //         "Arrhythmogenic right ventricular cardiomyopathy (ARVC) is a form of heart disease that usually appears in adulthood. ARVC is a disorder of the myocardium, which is the muscular wall of the heart. This condition causes part of the myocardium to break down over time, increasing the risk of an abnormal heartbeat (arrhythmia) and sudden death."
  //       );
  //     },
  //   },

  //   {
  //     value: "Aortopathies",
  //     title: "Aortopathies",
  //     associatedGene: [
  //       "ACTA2",
  //       "FBN1",
  //       "FOXE3",
  //       "LOX",
  //       "MFAP5",
  //       "MYH11",
  //       "MYLK",
  //       "PRKG1",
  //       "SMAD3",
  //       "TGFBR1",
  //       "TGFBR2",
  //     ],
  //     icard:
  //       "Familial thoracic aortic aneurysm and dissection (familial TAAD) involves problems with the aorta, which is the large blood vessel that distributes blood from the heart to the rest of the body.",
  //     popupFunction: function openModal110(event) {
  //       ModalService.open(
  //         "Aortopathies",
  //         "Familial thoracic aortic aneurysm and dissection (familial TAAD) involves problems with the aorta, which is the large blood vessel that distributes blood from the heart to the rest of the body. Familial TAAD affects the upper part of the aorta, near the heart. This part of the aorta is called the thoracic aorta because it is located in the chest (thorax). Other vessels that carry blood from the heart to the rest of the body (arteries) can also be affected. Familial TAAD may not be associated with other signs and symptoms. However, some individuals in affected families show mild features of related conditions called Marfan syndrome or Loeys-Dietz syndrome. These features include tall stature, stretch marks on the skin, an unusually large range of joint movement (joint hypermobility), and either a sunken or protruding chest. Occasionally, people with familial TAAD develop aneurysms in the brain or in the section of the aorta located in the abdomen (abdominal aorta).  "
  //       );
  //     },
  //   },

  //   {
  //     value: "restrictiveCardiomyopathy",
  //     title: "Restrictive cardiomyopathy",
  //     associatedGene: ["FLNC", "MYPN", "TNNI3", "TNNT2"],
  //     icard:
  //       "In people with familial restrictive cardiomyopathy, the heart muscle is stiff and cannot fully relax after each contraction.",
  //     popupFunction: function openModal50(event) {
  //       ModalService.open(
  //         "Restrictive cardiomyopathy",
  //         "In people with familial restrictive cardiomyopathy, the heart muscle is stiff and cannot fully relax after each contraction. Impaired muscle relaxation causes blood to back up in the atria and lungs, which reduces the amount of blood in the ventricles."
  //       );
  //     },
  //   },

  //   {
  //     value: "longQTsyndrome",
  //     title: "Long QT syndrome",
  //     associatedGene: [
  //       "AKAP9",
  //       "ANK2",
  //       "CACNA1C",
  //       "CALM1",
  //       "CALM2",
  //       "CALM3",
  //       "CAV3",
  //       "KCNE1",
  //       "KCNE2",
  //       "KCNH2",
  //       "KCNJ2",
  //       "KCNJ5",
  //       "KCNQ1",
  //       "SCN4B",
  //       "SCN5A",
  //       "SNTA1",
  //     ],
  //     icard:
  //       "Long QT syndrome (LQTS) is a conduction disorder, characterized by QT prolongation and T-wave abnormalities on the EKG.",
  //     popupFunction: function openModal60(event) {
  //       ModalService.open(
  //         "Long QT syndrome",
  //         "Long QT syndrome (LQTS) is a conduction disorder, characterized by QT prolongation and T-wave abnormalities on the EKG. A long QT interval can upset the careful timing of the heartbeat and cause sudden uncontrollable fast heart rhythms."
  //       );
  //     },
  //   },

  //   {
  //     value: "brugadaSyndrome",
  //     title: "Brugada syndrome",
  //     associatedGene: [
  //       "ABCC9",
  //       "CACNA1C",
  //       "CACNA2D1",
  //       "CACNB2",
  //       "GPD1L",
  //       "HCN4",
  //       "KCND3",
  //       "KCNE3",
  //       "KCNH2",
  //       "KCNJ8",
  //       "SCN1B",
  //       "SCN2B",
  //       "SCN3B",
  //       "SCN5A",
  //     ],
  //     icard:
  //       "Brugada syndrome is a condition that causes a disruption of the heart's normal rhythm.",
  //     popupFunction: function openModal80(event) {
  //       ModalService.open(
  //         "Brugada syndrome",
  //         "Brugada syndrome is a condition that causes a disruption of the heart's normal rhythm. Specifically, this disorder can lead to irregular heartbeats in the heart's lower chambers (ventricles), which is an abnormality called ventricular arrhythmia. If untreated, the irregular heartbeats can cause fainting (syncope), seizures, difficulty breathing, or sudden death. These complications typically occur when an affected person is resting or asleep."
  //       );
  //     },
  //   },

  //   {
  //     value: "catecholaminergicPolymorphic",
  //     title: "Catecholaminergic polymorphic ventricular tachycardia",
  //     associatedGene: [
  //       "CALM1",
  //       "CALM2",
  //       "CALM3",
  //       "CASQ2",
  //       "KCNJ2",
  //       "RYR2",
  //       "TRDN",
  //     ],
  //     icard:
  //       "Catecholaminergic polymorphic ventricular tachycardia (CPVT) is a condition characterized by an abnormal heart rhythm (arrhythmia).",
  //     popupFunction: function openModal90(event) {
  //       ModalService.open(
  //         "Catecholaminergic polymorphic ventricular tachycardia",
  //         "Catecholaminergic polymorphic ventricular tachycardia (CPVT) is a condition characterized by an abnormal heart rhythm (arrhythmia). As the heart rate increases in response to physical activity or emotional stress, it can trigger an abnormally fast heartbeat called ventricular tachycardia."
  //       );
  //     },
  //   },

  //   {
  //     value: "shortQT",
  //     title: "Short QT syndrome",
  //     associatedGene: ["KCNH2", "KCNJ2", "KCNQ1"],
  //     icard:
  //       "Short QT syndrome is a condition that can cause a disruption of the heart's normal rhythm (arrhythmia). In people with this condition, the heart (cardiac) muscle takes less time than usual to recharge between beats.",
  //     popupFunction: function openModal70(event) {
  //       ModalService.open(
  //         "Short QT syndrome",
  //         "Short QT syndrome is a condition that can cause a disruption of the heart's normal rhythm (arrhythmia). In people with this condition, the heart (cardiac) muscle takes less time than usual to recharge between beats. The term short QT refers to a specific pattern of heart activity that is detected with an electrocardiogram (EKG), which is a test used to measure the electrical activity of the heart. In people with this condition, the part of the heartbeat known as the QT interval is abnormally short."
  //       );
  //     },
  //   },

  //   {
  //     value: "familialatrialfibrillation",
  //     title: "Familial hypercholesterolemia",
  //     associatedGene: [
  //       "ABCA1",
  //       "APOA2",
  //       "APOB",
  //       "APOE",
  //       "EPHX2",
  //       "GSBS",
  //       "ITIH4",
  //       "LDLR",
  //       "LDLRAP1",
  //       "LPL",
  //       "PCSK9",
  //     ],
  //     icard:
  //       "Familial hypercholesterolemia is an inherited condition characterized by very high levels of cholesterol in the blood.",
  //     popupFunction: function openModal30(event) {
  //       ModalService.open(
  //         "Familial atrial fibrillation",
  //         "Familial atrial fibrillation is an inherited abnormality of the heart's normal rhythm. Atrial fibrillation is characterized by episodes of uncoordinated electrical activity (fibrillation) in the heart's upper chambers (the atria), which cause a fast and irregular heartbeat."
  //       );
  //     },
  //   },

  //   {
  //     value: "ehlersDanlossyndromevascular type",
  //     title: "Ehlers–Danlos syndrome, vascular type",
  //     associatedGene: ["COL3A1"],
  //     icard:
  //       "Ehlers-Danlos syndrome is a group of disorders that affect the connective tissues that support the skin, bones, blood vessels, and many other organs and tissues.",
  //     popupFunction: function openModal120(event) {
  //       ModalService.open(
  //         "Ehlers–Danlos syndrome, vascular type ",
  //         "Ehlers-Danlos syndrome is a group of disorders that affect the connective tissues that support the skin, bones, blood vessels, and many other organs and tissues. The vascular type can cause potentially life-threatening complications, including tearing (rupture) of blood vessels, intestines, and other organs. Bleeding problems are common in the vascular type of Ehlers-Danlos syndrome and are caused by unpredictable tearing (rupture) of blood vessels and organs. These complications can lead to easy bruising, internal bleeding, a hole in the wall of the intestine (intestinal perforation), or stroke. During pregnancy, women with vascular Ehlers-Danlos syndrome may experience rupture of the uterus. Additional forms of Ehlers-Danlos syndrome that involve rupture of the blood vessels include the kyphoscoliotic, classical, and classical-like types.The majority (60%) of individuals with vEDS who are diagnosed before age 18 years are identified because of a positive family history."
  //       );
  //     },
  //   },
  // ];

  const cardioTypes = curation.getCurationData('Hereditary Cardio Report')
  props.onLoad(true);
  function openPopup(data) {
    PopService.open("Cardio Rport", {
      data,
    });
  }

  // PopUp end

  const navigate = useNavigate();
  const naviagteToContact = () => {
    navigate("/contact");
  };
  const topRef = useRef();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="pageWrapper2">
      {/* <ScrollToTop/> */}
      <div className="innerWrapper d-flex flex-column mb-3 container-fluid">
        <div ref={topRef}></div>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadForMobile">
            <li className="breadcrumb-item">
              <a href={"#/dashboard" + location.search}>Dashboard</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/dashboard" + location.search}>Adult Onset</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/cardio" + location.search}>Cardio</a>
            </li>
            <li
              className="breadcrumb-item active bg1 marginTop"
              aria-current="page"
            >
              Results
            </li>
          </ol>
        </nav>
        {/* {isPanelOpen && (
        <Sidepanel open={isPanelOpen} onCloseCallback={onPanelClose} />
      )} */}
        <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
          <Row>
            <Col>
              <h1 className="ff1 fs30 fw600 text-muted pt-3">Hereditary Cardiovascular Results</h1>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className="fs20 pb-4">
                {/* {draftOrder?.Report_JSON?.report?.findings?.find(
                  (finding) => finding?.category === "Hereditary Cardio Report"
                )?.num_observations_of_significance === 0 && (
                  
                ) } */}
                {
                  cardioFindings?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          cardioFindings?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          cardioFindings?.num_observations_of_significance}
                      </span>
                      observation/s of significance.
                    </>
                  ) :
                    <>  No observation/s of significance were detected.</>
                }
                {
                  <NotesAndLimitationsModal curationData={cardioTypes} draftOrder={draftOrder} />
                }
              </h4>
            </Col>
          </Row>


          {
            cardioFindings?.num_observations_of_significance > 0 ? (<>
              <Row className="mt-5">
                <Col className="d-flex">
                  <h2 className="ff1 fs24 fw700">
                    Summary of Observations of Significance
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col style={{ padding: "0px 16px" }}>
                  <div className="table-responsive border border-info">
                    <Table className="table table-striped table-responsive report-table mb-0 summary-report-table">
                      <thead>
                        <tr className="fw600 bg-info bg-gradient bg-opacity-50 text-nowrap">
                          <td style={{ width: "25%" }} title='Individual disorders bundled together due to similar clinical features or biological pathway of origin'>Broad Disorder Type <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "20%" }} title='Specific clinical disease identified by the variant seen in the gene associated with the cause of disease'>Specific Disorder <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Type of chromosome on which a gene is located (autosomal or X linked) and whether the variant is likely to manifest as disease (dominant or recessive). Eg; An “Autosomal Dominant” or “AD” allocation refers to the gene being autosomal in origin and the nature of the variant being “dominant” or “likely disease causing”'>Inheritance <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Number of copies of a gene variant. Heterozygous refers to a single copy of the gene variant and Homozygous refers to both copies of a gene carrying a variant'>Zygosity <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Name of the Gene carrying the variation'>Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Whether a Variant associated with “disease” has been called out or not'>Variant Detected <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "15%" }} title='Technical nomenclature for the “Address” on the genome where the variant is located'>Gene Variant <sup className="small-text">&#9432;</sup></td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          //TODO: remove detailed_findings after regenerating all reports in backened
                          (cardioFindings?.summary_findings || cardioFindings?.detailed_findings)?.map((finding) => {
                            const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
                            if (isVariantDetected) {
                              return (
                                <tr>
                                  <td>
                                    <p>{finding?.subcategory[0]}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.subcategory[1]}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.inheritance}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.zygosity}</p>
                                  </td>
                                  <td style={{
                                    fontStyle: 'italic',
                                    textAlign: 'start',
                                  }}>
                                    <p>{finding?.biomarkers?.gene_symbol}</p>
                                  </td>
                                  <td>
                                    <VariantStatus text={finding?.biomarkers?.relevant || finding?.biomarkers?.cnv_status} draftOrder={draftOrder} />
                                  </td>
                                  <td>
                                    {finding?.biomarkers?.variant_description?.genomic_hgvs}
                                    <p style={{ margin: 0 }}>{finding?.biomarkers?.variant_description?.cdna_hgvs}</p>
                                    <p>{finding?.biomarkers?.variant_description?.protein_hgvs}</p>
                                  </td>
                                </tr>
                              )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </>) : ""
          }

          {
            summaryDetails.length > 0 ? (
              <>
                {/* Render your second section */}
                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RISK_IMPLICATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Risk Implications</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Risk Implication Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RISK_IMPLICATION && (
                            <div key={index} className="data-item">
                              <p>{data.RISK_IMPLICATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RECOMMENDATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Recommendations</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Recommendation Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RECOMMENDATION && (
                            <div key={index} className="data-item">
                              <p>{data.RECOMMENDATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : ""
          }

          <Row className="mt-5">
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw700">Detailed Findings</h2>
              </div>
            </Col>
          </Row>

          <div className="table-health-1 reportTable border border-dark-200">
            <Table
              striped
              responsive="lg"
              className="table report-table mb-0 detailed-report-table"
            >
              <thead className="row-head">
                <tr className="fw600 bg-primary bg-gradient bg-opacity-50 text-white text-nowrap">
                  <td title='Individual disorders bundled together due to similar clinical features or biological pathway of origin'>
                    Broad Disorder Type <sup className="small-text">&#9432;</sup>
                  </td>
                  <td style={{ width: "auto" }} title='Specific clinical disease identified by the variant seen in the gene associated with the cause of disease'>Specific Disorder <sup className="small-text">&#9432;</sup></td>
                  <td style={{ width: "45%" }} title='Variants in gene(s) that are known to cause the specific disorder'> Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                  <td style={{ width: "15%" }} title='Genes that contain variants that are likely to be disease causing'> Gene(s) with Variants <sup className="small-text">&#9432;</sup></td>
                  <td title='Whether a variant in a gene that can cause disease is present or absent'>Reportable Mutation Detected <sup className="small-text">&#9432;</sup></td>
                </tr>
              </thead>
              <tbody>
                {cardioTypes?.map((item) => (
                  <tr>
                    <td>{getDisorderType(item?.diorderType)} </td>
                    <td className="firstRowRight">
                      <div className="dropdown">
                        <button className="btn btn-link text-start" onClick={item?.popupFunction}>
                          {item?.title}
                        </button>
                        <div className="dropdown-content popToolTip">
                          <p>
                            {item?.icard}
                          </p>
                          <Link
                            onClick={item?.popupFunction}
                            style={{
                              cursor: "pointer",
                              fontSize: "12px",
                              color: "#0000EE",
                            }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>{" "}
                    </td>
                    <td style={{
                      fontStyle: 'italic',
                      textAlign: 'start',
                      wordBreak: 'break-word',
                    }}>
                      {item?.associatedGene.join(", ")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {cardioFindings?.detailed_findings?.map((detailed_finding) => {
                        const subcategory = detailed_finding?.subcategory;
                        const isVariantDetected = ReportUtils.isVariantDetected(detailed_finding?.biomarkers?.cnv_status);
                        if (isVariantDetected && subcategory?.includes(item?.title)) {
                          return (
                            <button
                              className="highlighted-variant"
                              onClick={() =>
                                openPopup(detailed_finding?.biomarkers)
                              }
                            >
                              {detailed_finding?.biomarkers?.gene_symbol}
                            </button>
                          )
                        }
                      })}
                    </td>
                    <td>
                      <VariantStatus text={ReportUtils.getVariantDetectedText(item, patientGender, cardioFindings?.detailed_findings)} gene={item?.associatedGene[0]} draftOrder={draftOrder} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Container fluid>
            <Row className="mt-5">
              <Col className="p-c2">
                <div className="d-flex flex-column">
                  <div className="d-md-flex flex-column report-buttons  text-center text-md-left">
                    <button
                      className="schApp text-center position-relative"
                      onClick={naviagteToContact}
                    >
                      <span className="schIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="#000"
                          className="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </span>
                      {""}Schedule appointment with Genetic Counsellor for detailed
                      explanation
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <Row>
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw600">Recommendations</h2>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col lg={12} md={6} xs={12}>
              <div className="mb-3">
                {/* <p className="fs18">
                  Genetic counseling is recommended to discuss the implications of
                  this test result. Schedule genetic counselor appointment at{" "}
                  <span>hello@strandls.com.</span> Test results should be
                  interpreted in the context of this individual's personal and
                  family history of cancer. Kindly consult with a physician before
                  taking any interventional steps.
                </p> */}

                <h4 className="fs18 fw800 fst-italic">Disclaimer</h4>
                <p className="pt-0 fs18 fst-italic">
                  {" "}
                  Note that the above information is not intended for any action
                  on your part for diagnosis, cure, mitigation, treatment or
                  prevention of disease. Any action based on this information
                  should be taken only on the recommendation of a medical
                  specialist.
                </p>
              </div>
            </Col>
          </Row>

          <button className="btn7 mt-5" onClick={() => ReportUtils.downloadReport(orderId, ReportUtils.reportTypes.cardio_risk)}>
            <span>{<DownloadIcon />}</span>
            Download Report (PDF)
          </button>
        </div>
      </div>
    </div>
  );
}
