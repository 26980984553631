import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./download.css";
import { GET_SINGLE_REPORT_URL } from "../../config";
import ReportUtils from "../../../src/utils/ReportUtils.js";
import api from "../../utils/api";

export default class Download extends React.Component {
  constructor(props) {
    super();
    this.state = {
      reportData: "",
      orderId: "",
      details:{}
    };
    props.onLoad(false);
  }

  getData = async () => {
    let id = new URLSearchParams(location.search).get("orderId") || localStorage.getItem("orderId");
    this.setState({orderId: id});
    // TODO
    // const id = new URLSearchParams(location.search).get("orderId");
    // this.setState({orderId: id || localStorage.orderId});
    if (id) {

      api({
        url: `${GET_SINGLE_REPORT_URL}`,
        method: "POST",
        params: { orderId: id },
      }).then((res) => {
        this.setState({details: res?.data[0]})
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  downloadReport = async () => {
    ReportUtils.downloadReport(this.state.orderId, ReportUtils.reportTypes.combined); 
  };

  render() {
    return (
      <div
        className="p-3"
        style={{
          backgroundImage: "url('/assets/images/strandBG.png')",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        {/* <div className='bgStrand' style={{backgroundImage:"url('/assets/images/strandBG.png')",backgroundRepeat:'no-repeat',height: '100%',
         width: '100%',  backgroundSize: 'cover'
    }}></div> */}
        <Container>
          <Row className="align-items-center">
            <Col>
              <h1 className="py-3 ff2 fw500">
                {/* <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#e14d45"
                    className="bi bi-fingerprint me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                    <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                    <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                    <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                    <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                  </svg>
                </span> */}
                Download GHI Report
              </h1>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={4} className="align-items-center">
              <div
                className="card p-4  bxShdow bdrNone "
                style={{
                  backgroundImage: "url('/assets/images/cardBG.png')",
                  backgroundRepeat: "no-repeat",
                  height: "100%",
                  width: "100%",
                  backgroundSize: "cover",
                }}
              >
                <div className="readyBadge">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="#fff"
                    className="bi bi-check2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                </div>
                <Row className="align-items-top">
                  <Col md={3}>
                    <div className="img-fluid">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        fill="#f9c976"
                        className="bi bi-file-medical mb-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.5 4.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L7 6l-.549.317a.5.5 0 1 0 .5.866l.549-.317V7.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L9 6l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V4.5zM5.5 9a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </div>
                  </Col>
                  <Col>
                    <h2 className="fs20 c6 fw600 mt-0 mb-0 pb-1 ff1">
                      {this.state.details?.Report_JSON?.report?.demographics?.name}
                    </h2>
                    <h2 className="fs12 c4 mt-0">
                      {this.state.details?.Order_ID?.display_value}
                    </h2>
                    <h2 className="fs14 text-muted">
                      <span className="fs10 fw-lighter d-block text-muted">
                        Collected on:
                      </span>
                      {
                        this.state.details?.Report_JSON?.report?.demographics
                          ?.sample_collection_date
                      }
                    </h2>

                    <h2 className="fs14 text-muted">
                      <span className="fs10 fw-lighter d-block text-muted">
                        Report Generated on:
                      </span>
                      {this.state.details?.Report_JSON?.report?.demographics?.report_date}
                    </h2>
                  </Col>
                </Row>

                <Row className="justify-content-between">
                  {/* <Col><Link className='text-decoration-none' to=""> <h2 className='fs10 mt-4 btn4 text-uppercase'>View Report
                    <i style={{marginLeft:"5px"}} className="fa-solid fa-eye"></i></h2></Link></Col> */}
                  <Col>
                    <button
                      className="fs10 my-4 btn4 text-uppercase"
                      onClick={() => this.downloadReport()}
                    >
                      Download
                      <i
                        style={{ marginLeft: "5px" }}
                        className="fa-solid fa-file-arrow-down"
                      ></i>
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col>
              <div className="p-4">
                <h2 className="ff1 fs30 fw400">
                  {" "}
                  Genomic Health Insights Report analyses over{" "}
                  <span className="c5"> 20,000 genes </span> to identify effects
                  of inherited mutations in three ways 
                </h2>
                <ul className="list-unstyled">
                  <li>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        className="bi bi-arrow-right-short pe-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </span>
                    Risk of development of cardiovascular, cancer, metabolic and
                    neurological disease
                  </li>
                  <li>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        className="bi bi-arrow-right-short pe-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </span>
                    Risk of disorders in your children if both parents have
                    similar carrier mutations
                  </li>
                  <li>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        className="bi bi-arrow-right-short pe-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                        />
                      </svg>
                    </span>
                    Response to certain drugs
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {/* <table className="table download-table">
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Health Insights</th>
              <th style={{ width: '20%' }}>Sample Collection Date</th>
              <th style={{ width: '15%' }}>Report Date</th>
              <th style={{ width: '15%' }}>Report Status</th>
              <th style={{ width: '15%' }}>View Report</th>
              <th style={{ width: '15%' }}>Download Report</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cancer</td>
              <td>12/08/2022</td>
              <td>20/08/2022</td>
              <td>Positive</td>
              <td className="icon-report">
                <img
                  alt=""
                  src="https://github.com/nehasaipangallu/strand2/blob/main/public/report%20orange.svg?raw=true"
                />
              </td>
              <td className="icon-download">
                {' '}
                <img
                  alt=""
                  src="https://github.com/nehasaipangallu/strand2/blob/main/public/download%20orange.svg?raw=true"
                />
              </td>
            </tr>
            <tr>
              <td>Cardio</td>
              <td>12/08/2022</td>
              <td>20/08/2022</td>
              <td>Negative</td>
              <td className="icon-report">
                <img
                  alt=""
                  src="https://github.com/nehasaipangallu/strand2/blob/main/public/report%20orange.svg?raw=true"
                />
              </td>
              <td className="icon-download">
                {' '}
                <img
                  alt=""
                  src="https://github.com/nehasaipangallu/strand2/blob/main/public/download%20orange.svg?raw=true"
                />
              </td>
            </tr>
            <tr>
              <td>Carrier Risk</td>
              <td>20/06/2022</td>
              <td>28/06/2022</td>
              <td>Positive</td>
              <td className="icon-report">
                <img
                  alt=""
                  src="https://github.com/nehasaipangallu/strand2/blob/main/public/report%20orange.svg?raw=true"
                />
              </td>
              <td className="icon-download">
                <img
                  alt=""
                  src="https://github.com/nehasaipangallu/strand2/blob/main/public/download%20orange.svg?raw=true"
                />
              </td>
            </tr>
          </tbody>
        </table> */}
        </Container>
      </div>
    );
  }
}
