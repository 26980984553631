import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Route, Routes } from "react-router-dom";
// import Chat from './components/chatbot/Chat';

import Header from "./components/header/header";
import Sidebar from "./components/side-bar/Sidebar";
import Home from "./components/home/home";
import Login from "./components/login/Login";
import AdultOnset from "./components/adult-onset/adult-onset";
import Cancer from "./components/cancer/cancer";
import Cardio from "./components/cardio/Cardio";
import CancerReport from "./components/cancer-report/cancer-report";
import Download from "./components/download/download";
import Articles from "./components/articles/articles";
import Faq from "./components/faq/faq";
import Metabolism from "./components/metabolism/Metabolism";
import Carrier from "./components/crrier-risk/Carrier";
import Pop from "./components/pop-up/Pop";
import Carrepo from "./components/cardio-report/Carrepo";
import Pharmaco from "./components/pharmacogenomics/Pharmaco";
import Metarepo from "./components/metabolic-report/Metarepo";
import Carpo from "./components/carrier-risk-repo/Carpo";
import Pharmarepo from "./components/pharma-repo/Pharmarepo";
import Dashboard from "./components/dashboard/dashboard";
import Contact from "./components/contact/contact";
import MyAccount from "./components/MyAccount/MyAccount";
import Consult from "./components/consult/Consult";
import Thankyou from "./components/thank-you/Thankyou";
import Modal from "./components/modal/Modal";

import ProtectedRoute from "./helpers/ProtectedRoutes";
import LoginNew from "./components/login/LoginNew";
import ReviewPage from "./components/review-page/ReviewPage";
import { APP_ENV } from "./config";

// import AppRoutes from "./AppRoutes"

export default function App() {
  // const location = window.location.hash || '';
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  const toRef = useRef();
  const [orderId, setOrderId] = useState(localStorage.getItem("orderId"));
  const [isLoggedin, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedin")
  );

  function onLogIn() {
    setIsLoggedIn("true");
    setOrderId(localStorage.getItem("orderId"))
  }

  // this is a temporary dolution to hide the sidebar based on route.
  //different solution must be found
  function showSideBar(value) {
    setSidebarVisibility(value);
  }

  function isUserClient() {
    return (APP_ENV === "client") ? true : false;
  }

  return (
    <div className="container-fluid p-0" ref={toRef}>
      <div className="row p-0 m-0">
        <Header />

        <div
          className="col-md-1 p-0 sidebarWrap"
          style={{
            display:
              sidebarVisibility && isLoggedin == "true" ? "block" : "none",
          }}
        >
          <Sidebar />

          <div className="modalDesign">
            <Modal />
          </div>
        </div>
        <div
          className={`p-0 ${sidebarVisibility && isLoggedin == "true"
            ? "col-md-11 contentWrap"
            : "col-md-12"
            }`}
        >
          <div
            className="pageWrapper"
            onScroll={(element) => {
              const position = element.target.getBoundingClientRect();
            }}
          >
            <Routes>
              <>
                <Route
                  exact
                  path="/"
                  element={<Home onLoad={showSideBar} />}
                ></Route>

                <Route
                  exact
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  exact
                  path="/:user/dashboard"
                  element={<Dashboard onLoad={showSideBar} />}
                ></Route>

                <Route
                  exact
                  path="/adult-onset"
                  element={
                    <ProtectedRoute>
                      <AdultOnset onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/cancer"
                  element={
                    <ProtectedRoute>
                      <Cancer onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/cardio"
                  element={
                    <ProtectedRoute>
                      <Cardio onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/metabolism"
                  element={
                    <ProtectedRoute>
                      <Metabolism onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/crrierrisk"
                  element={
                    <ProtectedRoute>
                      <Carrier onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  exact
                  path="/myaccount"
                  element={
                    <ProtectedRoute>
                      <MyAccount onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>



                <Route
                  exact
                  path="/carrierriskrepo"
                  element={
                    <ProtectedRoute>
                      <Carpo onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  exact
                  path="/pharmacogenomics"
                  element={
                    <ProtectedRoute>
                      <Pharmaco onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  exact
                  path="/pharma-repo"
                  element={
                    <ProtectedRoute>
                      <Pharmarepo onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  exact
                  path="/cancerreport"
                  element={
                    <ProtectedRoute>
                      <CancerReport onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/cardio-report"
                  element={
                    <ProtectedRoute>
                      <Carrepo onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/metabolic-report"
                  element={
                    <ProtectedRoute>
                      <Metarepo onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  exact
                  path="/download"
                  element={
                    <ProtectedRoute>
                      <Download onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>

                {/* <Route
                  exact
                  path="/article-readmore/:id"
                  element={
                    <ProtectedRoute>
                      <Articlereadmore onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route> */}

                <Route
                  exact
                  path="/thank-you"
                  element={
                    <ProtectedRoute>
                      <Thankyou onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                {isUserClient() && 
                  <Route
                  exact
                  path="/login"
                  element={<Login onLogInCbk={onLogIn} onLoad={showSideBar} />}
                ></Route>
                }
                {!isUserClient() && 
                <Route
                  exact
                  path="/login"
                  element={<LoginNew onLogInCbk={onLogIn} onLoad={showSideBar} />}
                ></Route>
                } 

                <Route
                  exact
                  path="/home"
                  element={<Home onLoad={showSideBar} />}
                ></Route>

                <Route
                  exact
                  path="/articles"
                  element={
                    <ProtectedRoute>
                      <Articles onLoad={showSideBar} />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  exact
                  path="/faq"
                  element={<Faq onLoad={showSideBar} />}
                ></Route>
                <Route
                  exact
                  path="/contact"
                  element={<Contact onLoad={showSideBar} />}
                ></Route>

                <Route
                  exact
                  path="/consult"
                  element={<Consult onLoad={showSideBar} />}
                ></Route>

                <Route
                  exact
                  path="/welcome"
                  element={<Dashboard onLoad={showSideBar} />}
                ></Route>

                <Route
                  exact
                  path="/review"
                  element={<ProtectedRoute><ReviewPage onLoad={showSideBar} /></ProtectedRoute>}
                ></Route>

                <Route
                  exact
                  path="/articles"
                  element={<Articles onLoad={showSideBar} />}
                ></Route>
              
              </>
            </Routes>

            {/* <Chat /> */}
          </div>
        </div>

        <Pop />
      </div>
    </div>
  );
}
