import React from 'react';
import './adult-onset.css';

export default class AdultOnset extends React.Component {
  constructor(props) {
    super();
    this.state = {};
    props.onLoad(true);
  }

  onVeiwReortClick = () => {};

  render() {
    return (
      <div className="adult-onset">
        <div className="d-flex justify-content-end align-items-center adult-img-container">
          <img
            src="https://github.com/nehasaipangallu/strand2/blob/main/public/Group%2048095784.svg?raw=true"
            className="flow-img m-4"
          />
        </div>
        <div className="d-flex adult-route-container m-5 flex-wrap">
          <div className="route-row-1 m-3 d-flex flex-column justify-content-evenly flex-wrap">
            <h4 className="row-1-heading">Adult Onset</h4>
            <div className="d-flex flex-row justify-content-evenly">
              <div className="row-1-card m-3">
                <img src="https://github.com/nehasaipangallu/strand2/blob/main/public/cancer.svg?raw=true" />
                <h3>Cancer</h3>
                <button
                  className="btn btn2 m-2"
                  onClick={this.onVeiwReortClick}
                >
                  View Results
                </button>
              </div>
              <div className="row-1-card m-3">
                <img src="https://github.com/nehasaipangallu/strand2/blob/main/public/cardio.svg?raw=true" />
                <h3>Cardio</h3>
                <button
                  className="btn btn2 m-2"
                  onClick={this.onVeiwReortClick}
                >
                  View Results
                </button>
              </div>
              <div className="row-1-card m-3">
                <img src="https://github.com/nehasaipangallu/strand2/blob/main/public/metabolism.svg?raw=true" />
                <h3>Metabolic</h3>
                <button
                  className="btn btn2 m-2"
                  onClick={this.onVeiwReortClick}
                >
                  View Results
                </button>
              </div>
            </div>
          </div>
          <div className="route-row-2 d-flex flex-row justify-content-evenly flex-wrap">
            <div className="d-flex flex-column">
              <h4 className="row-2-heading">Carrier Risk</h4>
              <div className="row-2-card m-4">
                <img src="https://github.com/nehasaipangallu/strand2/blob/main/public/spreading%201.svg?raw=true" />
                <h3>Cancer</h3>
                <button
                  className="btn btn2 m-2"
                  onClick={this.onVeiwReortClick}
                >
                  View Results
                </button>
              </div>
            </div>
            <div className="d-flex flex-column">
              <h4 className="row-2-heading">Pharmacogenomics</h4>
              <div className="row-2-card m-4">
                <img src="https://github.com/nehasaipangallu/strand2/blob/main/public/StrandBrandGuide-14%202.svg?raw=true" />
                <h3>Cardio</h3>
                <button
                  className="btn btn2 m-2"
                  onClick={this.onVeiwReortClick}
                >
                  View Results
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}
