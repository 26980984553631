import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./dashboard.css";
import Footer from "../footer/footer";
import { GET_SINGLE_REPORT_URL } from "../../config/index";
import NotesAndLimitations from "../notes-and-limitations/NotesAndLimitations";
import config from "../../config/index";
import api from "../../utils/api";

const GENE_STRUCTURE = "The GHI portal shows that I have some variants that cause an obvious change to gene structure. What are these variants? "

export default function Dashboard(props) {
  props.onLoad(false);

  const [geneStructureFaq, setGeneStructureFaq] = useState([]);

  const getFaqData = async () => {
    let res = await api.get("/faq/faqs");
    setGeneStructureFaq(res?.data?.data.find((faq) => faq.question === GENE_STRUCTURE).answer);
  };

  useEffect(() => {
    getFaqData();
  }, []);

  const [allPost, setAllPost] = useState([]);
  const location = useLocation();
  let { phoneNumber } = location?.state || {};
  const navigate = useNavigate();

  const [orderId, setOrderId] = useState();
  const scollToRef1 = useRef();
  const scollToRef2 = useRef();
  const [draftOrder, setDraftOrder] = useState([]);

  useEffect(() => {
    let _orderId = new URLSearchParams(location.search).get("orderId");
    console.log("dashboard:setOrderId:", _orderId);
    if (!_orderId) {
      _orderId = localStorage.getItem('orderId');
      navigate(`/dashboard?orderId=${_orderId}`)
      setOrderId(_orderId);
    }
    else {
      setOrderId(_orderId);
    }
  }, [location]);

  useEffect(() => {
    console.log("dashboard:useEffect:", orderId);
    if (orderId) {
      getData();
    }
  }, [orderId]);


  const getData = async () => {
    if (orderId) {
      console.log("dashboard:getData:", orderId);
      const reports = await api({
        url: GET_SINGLE_REPORT_URL,
        method: "POST",
        params: { orderId },
      });

      console.log("dashboard:getData:reports:", reports);
      setDraftOrder(reports?.data[0]);
    }
  };


  function onMenuClick(location) {
    navigate(location);
  }

  const topRef = useRef();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const renderGeneStructureFaq = () => {
    return (
      <div className="dropdown">
        <button className="dropbtn">
          <img
            src="info-circle.svg"
            width={12}
            className="ms-2 info-img"
          />
        </button>
        <div className="dropdown-content popToolTip" style={{ width: '450px' }}>
          <p style={{ fontSize: "12px", textAlign: "center" }}>
            {geneStructureFaq}
          </p>
        </div>
      </div>
    );
  }

  const isReportReady = () => {
    if (draftOrder?.hasOwnProperty("Status")) {
      if (config.isUserClient() && draftOrder?.Status === "Ready") {
        localStorage.setItem('reportReady', 1);
        return true;
      }
      if (!config.isUserClient() && (draftOrder?.Status === "Draft" || draftOrder?.Status === "Ready")) {
        localStorage.setItem('reportReady', 1);
        return true;
      }
      localStorage.setItem('reportReady', 0);
      return false;
    }

    localStorage.setItem('reportReady', 0);
    return false;
  }

  return (
    <>
      <div ref={topRef}></div>

      {/* <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }} className="m-2 mt-3 head-text">
              <h6>You are currently viewing </h6>
              <h6 className="ms-2 font-weight-bold">{draftOrder?.Order_ID?.display_value}</h6>
            </div> */}
      <div className="container-fluid innerWrapper">
        <div
          className="featuredSection"
          style={{
            backgroundImage: "url('/assets/images/bgcircle.png')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="card p-3 p-md-3 bxShdow bdrNone cardTransparent">
            <div className="row p-3">
              <div className="col-md-12">
                <h1 className="c2 ms-0 p-4 display-3 text-secondary">
                  Welcome to Genomic Health Insights
                </h1>
              </div>
              <div className="col-md-6 pt-2 pe-4">
                <h3 className="mt-3 fs20">Purpose</h3>{" "}
                <p className="fs16 lh28 fw500">
                  {" "}
                  Our knowledge of the genome and its impact on our health is
                  growing rapidly. Genomic Health Insights brings this
                  personalized knowledge to you.
                </p>
                <h3 className="mt-3 fs20">Summary</h3>{" "}
                <p className="fs16 lh28  fw500">
                  {" "}
                  Genomic Health Insights Report analyses over 20,000 genes to
                  identify effects of inherited mutations in three ways.
                  <ul className="mt-2">
                    <li>
                      Risk of development of cardiovascular, cancer and metabolic disorders
                    </li>
                    <li>
                      Risk of disorders in your children if both parents have
                      similar carrier mutations
                    </li>
                    <li>Response to certain drugs</li>
                  </ul>
                </p>
              </div>
              <div className="col-md-6 text-center position-relative align-items-center">
                <div className="row align-items-center dasboardImagePadding">
                  <img
                    className="img-fluid align-self-center"
                    src="assets/images/v2.png"
                  ></img>
                </div>
              </div>
            </div>

            <div ref={scollToRef1}></div>

            <Row className="p-3 pt-0">
              <Col className="pt-0">
                <div className="fs16 lh28  fw500">
                  <h4 className="mt-2 fs20">Genes</h4>
                  The genome comprises a whopping 6 billion characters,
                  roughly half being contributed by each parent. The functions
                  of most of these characters are yet to be discovered.
                  However, tremendous progress over the last few years has
                  given us insights into ~2% of these characters that comprise
                  the ~20,000 genes in the human genome. These genes carry
                  recipes for the manufacture of molecules that are
                  responsible for keeping our bodies functioning.
                  <h4 className="mt-4 fs20">Gene Variants</h4>
                  Each of us carries ~50,000 personalized variants in these
                  genes relative to the “average” human. These variants make
                  each of us different from the other. Most of these variants
                  have only subtle effects, if any, on our health. But some
                  are more potent than the others. A few act single-handedly
                  or in small groups, while most act in large teams. For
                  example, did you know that your height is determined by tens
                  of thousands of variants acting together!
                  {/* <h2 className="mt-4 fs20">Your Genomic Insights</h2>

Here, we have sequenced all ~20,000 of your genes and identified your personalized variants. We have further distilled your personalized variants to identify those that meet a high enough scientific evidence bar to act single-handedly or in small groups in modulating your health or that of your progeny. The reports below explain these variants and their implication. */}
                </div>

                <h2 className="fs16 fw400 mt-2 lh22 fw500 ">
                  {" "}
                  {/* The DNA of an individual is organised into known functional
                    components called genes. There are over 20,000 genes in an
                    individual. Genomic Health Insights analyses these genes through
                    a combination of biochemical and computational methods and calls
                    out variations that may have an impact on one’s health and could
                    potentially have an impact in one’s progeny’s health. The latest
                    instrumentation and analytical methods are used to generate
                    reports which are accessed via an “easy-to-use” software
                    interface to inform participants of variations in their genes
                    that could impact their health and lifestyle. The reports are
                    displayed on the software as 5 discrete reports that analyse
                    various aspects of health, broadly addressing cancer health,
                    cardiovascular health, metabolic health, and progeny health. An
                    additional report on how a person’s genes may impact the choice
                    of drugs that they can use is also made available to all
                    participants. */}
                </h2>
                {/* <h2
                    className="fs14 cursorPointer btn4"
                    onClick={() => showMore()}
                  >
                    Show Less
                  </h2> */}
              </Col>
            </Row>
          </div>
        </div>



        <>
          <Row>
            <Col>
              <div style={{ display: "flex", marginTop: "30px" }}>
                <h1 className="ff1 fw500 mt-3 text-secondary">Your Genomic Insights</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card p-4 mt-2 bxShdow bdrNone cardTransparent">
                <div className="fs16 lh28  fw500 py-3">
                  Here, we have sequenced all ~20,000 of your genes and
                  identified your personalized variants. We have further
                  distilled your personalized variants to identify those that
                  meet a high enough scientific evidence bar to act
                  single-handedly or in small groups in modulating your health
                  or that of your progeny. The reports below explain these
                  variants and their implication.
                  {isReportReady() && draftOrder?.Report_JSON?.report?.hasOwnProperty("personalized_stats") &&
                    <>
                      <h3 className="mt-3">
                        Before you drill into these reports, these are your
                        personalized statistics:
                      </h3>
                      <ul>
                        <li>
                          {draftOrder?.Report_JSON?.report?.personalized_stats
                            ?.num_genomic_variants}{" "}
                          personalized genomic variants
                        </li>
                        <li>
                          {draftOrder?.Report_JSON?.report?.personalized_stats
                            ?.pct_homozygous_variants}
                          % of these are present in both copies of your genome
                          (one each from your mother and father)
                        </li>
                        <li>
                          {draftOrder?.Report_JSON?.report?.personalized_stats
                            ?.pct_non_affecting_variants}
                          % of these variants do not cause any change to the
                          gene structure<sup title={geneStructureFaq}>&#9432;</sup>
                        </li>
                        <li>
                          {draftOrder?.Report_JSON?.report?.personalized_stats
                            ?.pct_affecting_variants}
                          % of these variants cause changes to the gene
                          structure<sup title={geneStructureFaq}>&#9432;</sup>
                        </li>
                      </ul>
                    </>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </>


        {!isReportReady() && (
          <>
            <Row className="p-3">
              <div
                className="mt-4 p-5"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "orange",
                  fontWeight: "bold",
                  border: "2px dashed orange",
                  borderRadius: "6px",
                }}
              >
                Your Genomic Health Insight results are not ready yet. Typically
                it takes 21 working days from the date of collection for the
                results field to be populated. In the interim, we invite you to
                browse through the "Learn More" tabs below to further understand
                the areas covered by Genomic Health Insights. This will help you
                better understand your personal Genomic Health Insights when the
                results get published.
              </div>
            </Row>
          </>
        )}

        <Row>
          <Col>
            <div style={{ display: "flex", marginTop: "30px" }}>
              <h1 className=" ff1 fw500 mt-3 text-secondary">Adult Onset Conditions</h1>
            </div>
          </Col>
        </Row>

        <Row className="align-items-top p-1 adult-onset">
          <Col className="position-relative cardPadding" lg={4} md={6} sm={12}>
            <div className="card p-4 mt-2 bxShdow bdr-rad16 bdrNone cardTransparent cardHeight align-buttons-bottom ">
              <img
                className="img-fluid bdr-rad16 height100"
                src="/assets/images/heriditarycancer.jpg"
              />
              <h4 className="c2 fs26 ff1 mt-3 ">Hereditary Cancer Risk</h4>
              <p className="fs16 marginCard ">
                A thorough analysis of genes across all hereditary cancer types
                was conducted.{" "}
              </p>


              {isReportReady() && (
                <h6 className="fs16 highlightHolder">
                  {draftOrder?.Report_JSON?.report?.findings?.find(
                    (finding) =>
                      finding?.category === "Hereditary Cancer Risk Report"
                  )?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category ===
                              "Hereditary Cancer Risk Report"
                          )?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category ===
                              "Hereditary Cancer Risk Report"
                          )?.num_observations_of_significance}
                      </span>
                      observation/s of significance.
                    </>
                  ) : (
                    <> No observation/s of significance were detected.</>
                  )}

                </h6>
              )}

              <Row>
                <Col>
                  <button
                    disabled={!isReportReady()}
                    onClick={() => onMenuClick(`/cancerreport?orderId=${orderId}`)}
                    className="btn7 mt-2 me-2"
                  >
                    View Results
                  </button>
                  <button
                    onClick={() => onMenuClick(`/cancer?orderId=${orderId}`)}
                    className="btn5 mt-2"
                  >
                    Learn More
                  </button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col className="c-pharma cardPadding" lg={4} md={6} sm={12}>
            <div className="card p-4 mt-2 bxShdow bdr-rad16 bdrNone cardTransparent cardHeight align-buttons-bottom">
              <img
                className="img-fluid bdr-rad16 height100 "
                src="/assets/images/heriditarycardio.jpg"
              />
              <h4 className="c2 fs26 ff1 mt-3">
                Hereditary Cardiovascular Risk
              </h4>
              <p className="fs16 marginCard" style={{}}>
                Over 14 inherited cardiovascular conditions are addressed in
                this section.
              </p>{" "}
              <p></p>
              {isReportReady() && (
                <h6 className="fs16 highlightHolder">
                  {draftOrder?.Report_JSON?.report?.findings?.find(
                    (finding) =>
                      finding?.category === "Hereditary Cardio Report"
                  )?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category === "Hereditary Cardio Report"
                          )?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category === "Hereditary Cardio Report"
                          )?.num_observations_of_significance}
                      </span>
                      observation/s of significance.
                    </>
                  ) : (
                    <> No observation/s of significance were detected.</>
                  )}
                </h6>
              )}
              <Row>
                <Col>
                  <button
                    disabled={!isReportReady()}
                    onClick={() => onMenuClick(`/cardio-report?orderId=${orderId}`)}
                    className="btn7 mt-2 me-2"
                  >
                    View Results
                  </button>
                  <button
                    onClick={() => onMenuClick(`/cardio?orderId=${orderId}`)}
                    className="btn5 mt-2"
                  >
                    Learn More
                  </button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg={4} md={6} sm={12} className="metaCardPadding ">
            <div className="card p-4 mt-2 bxShdow bdr-rad16 bdrNone cardTransparent cardHeight align-buttons-bottom ">
              <img
                className="img-fluid bdr-rad16 height100"
                src="/assets/images/MetabolismReport.jpg"
              />
              <h4 className="c2 fs26 ff1 mt-3">In-born Errors of Metabolism</h4>
              <p className="fs16 marginCard">
                Over 58 unique conditions associated with adult onset in-born
                errors of metabolism are addressed in this section.
              </p>{" "}
              {isReportReady() && (
                <h6 className="fs16 highlightHolder">
                  {draftOrder?.Report_JSON?.report?.findings?.find(
                    (finding) =>
                      finding?.category ===
                      "In-born Errors of Metabolism Report"
                  )?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category ===
                              "In-born Errors of Metabolism Report"
                          )?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category ===
                              "In-born Errors of Metabolism Report"
                          )?.num_observations_of_significance}
                      </span>
                      observation/s of significance.
                    </>
                  ) : (
                    <> No observation/s of significance were detected.</>
                  )}
                </h6>
              )}
              <Row>
                <Col>
                  <button
                    disabled={!isReportReady()}
                    onClick={() => onMenuClick(`/metabolic-report?orderId=${orderId}`)}
                    className="btn7 mt-2 me-2"
                  >
                    View Results
                  </button>
                  <button
                    onClick={() => onMenuClick(`/metabolism?orderId=${orderId}`)}
                    className="btn5 mt-2"
                  >
                    Learn More
                  </button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{ display: "flex", marginTop: "30px" }}>
              <h1 className="ff1 fw500 mt-3 text-secondary">Additional Reports</h1>
            </div>
          </Col>
        </Row>

        <Row className="align-items-top p-1 justify-content-between aditional-repo ">
          <Col lg={4} md={6} sm={12} className="cardPadding">
            <div className="card p-4 bxShdow bdr-rad16 bdrNone mt-2 cardTransparent cardHeight align-buttons-bottom">
              <img
                className="img-fluid bdr-rad16 height100 objectPositionTop"
                src="/assets/images/carrierrisk.jpg"
              />
              <h4 className="c2 fs26 ff1 mt-3">My Carrier Risk Screening</h4>
              <p className="fs16 mt-3">
                Over 180 unique conditions associated with autosomal recessive
                and X-linked recessive disorders are addressed in this section.
              </p>{" "}
              {isReportReady() && (
                <h6 className="fs16 highlightHolder">
                  {draftOrder?.Report_JSON?.report?.findings?.find(
                    (finding) =>
                      finding?.category === "My Carrier Risk Screening Report"
                  )?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category ===
                              "My Carrier Risk Screening Report"
                          )?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          draftOrder?.Report_JSON?.report?.findings?.find(
                            (finding) =>
                              finding?.category ===
                              "My Carrier Risk Screening Report"
                          )?.num_observations_of_significance}
                      </span>
                      observation/s of significance.
                    </>
                  ) : (
                    <> No observation/s of significance were detected.</>
                  )}
                </h6>
              )}
              <Row>
                <Col>
                  <button
                    disabled={!isReportReady()}
                    onClick={() => onMenuClick(`/carrierriskrepo?orderId=${orderId}`)}
                    className="btn7 mt-2 me-2"
                  >
                    View Results
                  </button>
                  <button
                    onClick={() => onMenuClick(`/crrierrisk?orderId=${orderId}`)}
                    className="btn5 mt-2"
                  >
                    Learn More
                  </button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col className="c-pharma" lg={4} md={6} sm={12}>
            <div className="card p-4 bxShdow bdr-rad16 bdrNone mt-2 cardTransparent cardHeight align-buttons-bottom ">
              <img
                className="img-fluid bdr-rad16 height100"
                src="/assets/images/Pharmacogenomics.jpg"
              />
              <h4 className="c2 fs26 ff1 mt-3">Pharmacogenomics Screening</h4>

              <p className="fs16">
                The metabolism of over 100 different drugs across different
                therapeutic areas is characterized in this section.
              </p>
              <h6 className="fs16 highlightHolder d-none d-md-block"></h6>
              <h6 className="fs16 highlightHolder d-none d-md-block"></h6>
              <Row>
                <Col>
                  <button
                    disabled={!isReportReady()}
                    onClick={() => onMenuClick(`/pharma-repo?orderId=${orderId}`)}
                    className="btn7 mt-2 me-2"
                  >
                    PGx Results
                  </button>
                  <button
                    onClick={() => onMenuClick(`/pharmacogenomics?orderId=${orderId}`)}
                    className="btn5 mt-2"
                  >
                    Learn More
                  </button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="extra-column" lg={4} md={6} sm={12}></Col>
        </Row>
        <div className="notes-and-limitations">
          <Row>
            <Col>
              <div style={{ display: "flex", marginTop: "30px" }}>
                <h1 id="notes-and-limitations" className="ff1 fw500 mt-3 text-secondary">Notes and Limitations</h1>
              </div>
            </Col>
          </Row>
          <NotesAndLimitations draftOrder={draftOrder} isReportReady={isReportReady()} />
        </div>
        <Footer />
      </div>
    </>
  );
}