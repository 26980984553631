import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const scrollToTop = () => {
    // setInterval(function() {
    //   window.scrollTo(0, i);
    //   i += 10;
    //   if (i >= 200) clearInterval(int);
    // }, 20);

    setTimeout(() => {
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      })
    }, 6000)

  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);


}

// import React from 'react';

// const ScrollToTopButton = () => {
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth'
//     });
//   };

//   return (
//     <button onClick={scrollToTop}>Scroll to Top</button>
//   );
// };

// export default ScrollToTopButton;