import React, { useEffect, useState } from "react";
import "./cancer.css";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GET_SINGLE_REPORT_URL } from "../../config/index";
import GradingIcon from '@mui/icons-material/Grading';
import api from "../../utils/api";

export default function Cancer(props) {
  props.onLoad(true);
  const naviage = useNavigate();



  const [allPost, setAllPost] = useState([]);
  const [orderData, setOrder] = useState([]);
  const [orderId, setOrderId] = useState();
  const [allData, setAllData] = useState([]);

  const getData = async () => {
    let result = await api.get("/adultOnset/allCancer");
    // let res = await result.json();
    setAllPost(result?.data?.data);
    console.log("cancerPost: ", result);
  };

  useEffect(
    () => {
      getData();
    },
    []
    //
  );

  const [draftOrder, setDraftOrder] = useState(null);
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderIds = queryParameters.get("orderId") || localStorage.getItem("orderId");
    setOrderId(orderIds)
    // console.log("cancerId", orderIds)
    // const reports = await api({
    //   url: GET_SINGLE_REPORT_URL,
    //   method: "POST",
    //   params: { orderId },
    // });
    // console.log("new log carpo", reports);
    // // setDraftOrder(reports?.data[0]);
    // console.log("draftOrderscarpo", draftOrder)
  }

  function onVeiwReortClick() {
    naviage(`/cancerreport?orderId=${orderId}`);
  }
  return (
    <div className="innerWrapper ">
      <Row className="cancer-bread">
        <Col className="pt-2 bread-wid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadForMobile">
              <li className="breadcrumb-item">
                <a href={"#/dashboard" + location.search}>Dashboard</a>
              </li>
              <li className="breadcrumb-item marginTop">
                <a href={"#/dashboard" + location.search}>Adult Onset</a>
              </li>
              <li
                className="breadcrumb-item active bg1 marginTop"
                aria-current="page"
              >
                Cancer
              </li>
            </ol>
          </nav>
        </Col>
        <Col className="btncol">
          <button
            disabled={!localStorage.getItem('reportReady') || localStorage.getItem('reportReady') == 0}
            className="btn7 float-end"
            onClick={onVeiwReortClick}
          ><GradingIcon />View Results
          </button>
        </Col>
        {/* start repo sec */}
      </Row>
      {/* allposts  */}
      {allPost.map((item) => {
        return (
          <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
            <Row>
              <Col>
                <div style={{ display: "flex", marginTop: "10px" }}>

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">{item.section1_title}</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <div className="cancerDesc">
                <p className="fs18">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_description,
                    }}
                  />
                </p>
              </div>
            </Row>

            <Row>
              <Col>
                <div style={{ display: "flex", marginTop: "20px" }}>

                  <h2 className=" ff1 fs30 fw600 mt-3 text-secondary">{item.section2_title}</h2>
                </div>
              </Col>
            </Row>

            <div className="bdrNone cancerDesc">
              <Row>
                <Col>
                  <p className="fs18">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.section2_description,
                      }}
                    />
                  </p>
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <div style={{ display: "flex" }}>

                  <h2 className=" ff1 fs30 fw600 mt-3 text-secondary">If You Test Positive</h2>
                </div>
              </Col>
            </Row>

            <div className=" cancerDesc">
              <Row>
                <Col>
                  <p className="fs18">
                    Medical guidelines today offer a variety of options for
                    controlling risk in the event you test positive. These can
                    range from more frequent monitoring to a few more proactive
                    interventions that can reduce the risk. An oncologist, a
                    gynecologist or a genetic counselor is best placed to advise
                    you on these options.
                  </p>
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <div style={{ display: "flex" }}>

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">If You Test Negative</h2>
                </div>
              </Col>
            </Row>

            <div className="cancerDesc">
              <Row>
                <Col>
                  <p className="fs18">
                    Based on our current knowledge, you are not at increased
                    risk from the known genomic causes covered by this test.
                    However, scientific knowledge is advancing rapidly so please
                    watch your account for any updates as more information
                    becomes available. In addition, note that even in the
                    absence of pronounced inherited risk, all individuals have a
                    base level of risk for cancer on account of environmental
                    influences as well as age-related changes.
                  </p>
                </Col>
              </Row>
            </div>

            <Row>
              <Col>
                <div style={{ display: "flex" }}>

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">{item.section3_title}</h2>
                </div>
              </Col>
              <Container className="ps-4">
                <div className="card bxShdow bdrNone d-md-flex mb-3 marginCard">
                  {/* image sec */}
                  <Row>
                    <Col md={4}>
                      <div className="p-4 mt-2 ">
                        <img className="img-fluid" src="/Graph.png" />
                      </div>
                    </Col>

                    <Col md={8}>
                      <div className="p-3 mt-5 ms-2">

                        <Table responsive="sm">
                          <thead className="table-cont">
                            <tr>
                              <td>
                                <span className="s-1 fs18">Type of Cancer</span>{" "}
                              </td>
                              <td>
                                <span className="s-1 fs18">
                                  Commonly associated Cancer Genes
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Breast Cancer</td>
                              <td style={{
                                fontStyle: 'italic',
                                textAlign: 'start',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}>
                                BRCA1, BRCA2, p53, PTEN, CDH1</td>
                            </tr>
                            <tr>
                              <td>Colorectal Cancer</td>
                              <td>MLH1, MSH2, MSH6, PMS2, MYH, APC, STK11</td>
                            </tr>
                            <tr>
                              <td>Ovarian Cancer</td>
                              <td style={{
                                fontStyle: 'italic',
                                textAlign: 'start',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}>
                                BRCA1, BRCA2, MLH1, MSH2, MSH6, PMS2, P53</td>
                            </tr>
                            <tr>
                              <td>Prostate Cancer</td>
                              <td style={{
                                fontStyle: 'italic',
                                textAlign: 'start',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}>
                                BRCA2, RNASEL ELAC2, MSR1</td>
                            </tr>
                            <tr>
                              <td>Pancreatic Cancer</td>
                              <td style={{
                                fontStyle: 'italic',
                                textAlign: 'start',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}>
                                BRCA2, STK11/LKB1, PALB2, PRSS1, SPINKKI, CDKN2A
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Row>

            {/* end for table section */}

            {/* start this sec for car */}

            {/* this is here we get on contnet refer figma */}
            <div>
              <Row>
                <Col>
                  <div style={{ display: "flex", marginTop: "30px" }}>

                    <h2 className="ff1 fs30 fw600 mt-3 text-secondary">{item?.section4_title}</h2>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="p-2">
              {/* end for cap heading  */}

              <Container className="ps-4">
                <Row className="align-items-center">
                  <Carousel className="car-bg">
                    <Carousel.Item>
                      <Row className="carousel-item-container">
                        <div className="carousel-cap">
                          <h2 className="fs28 mt-3">Lynch syndrome</h2>
                          <p className="fs18">
                            Lynch syndrome is a type of inherited cancer
                            syndrome associated with a genetic predisposition to
                            different cancer types. This means people with Lynch
                            syndrome have a higher risk of certain types of
                            cancer. Lynch Syndrome is also known as hereditary
                            non-polyposis colorectal cancer (HNPCC).
                          </p>
                        </div>

                        <div className="justify-content-between align-items-center">
                          <h2 className="fs28 mt-3">
                            Most common cancers in Lynch Syndrome
                          </h2>
                          <img
                            className="img-fluid img123"
                            src="/assets/images/Lynch.png"
                            alt="Second slide"
                          />
                        </div>
                        <p className="fs18">
                          Other, less-common cancers associated with Lynch
                          syndrome are hepatobiliary tract cancer, small bowel
                          cancer, brain cancer, and sebaceous neoplasms (skin
                          cancer).
                        </p>
                      </Row>
                    </Carousel.Item>
                    {/* end item-1 */}

                    <Carousel.Item>
                      <Row className="carousel-item-container">
                        <div className="justify-content-end align-items-center">
                          <div className="carousel-cap">
                            <h2 className="fs28 mt-3">
                              Hereditary Breast and Ovarian Cancer Syndrome
                              (HBOC)
                            </h2>
                            <p className="fs18">
                              Hereditary Breast and Ovarian Cancer syndrome
                              (HBOC) is a genetic condition that makes it more
                              likely that a person will get breast, ovarian, and
                              other cancers. HBOC is hereditary, meaning that it
                              is caused by a mutation (genetic change) that can
                              be passed down in families.
                            </p>
                          </div>
                        </div>

                        <div className="p-2">
                          <img
                            className="img-fluid"
                            src="/assets/images/Hereditary.png"
                            alt="Second slide"
                          />
                        </div>
                      </Row>
                    </Carousel.Item>
                    {/* end item-2 */}
                  </Carousel>
                </Row>
              </Container>
            </div>

            <Row>
              <Col>
                <div style={{ display: "flex", marginTop: "30px" }}>

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">
                    Celebrities Who Have Announced They Are Positive
                  </h2>
                </div>
              </Col>
            </Row>

            <div className="cancerDesc">
              <Row>
                <Col>
                  <p className="fs18">
                    <ul>
                      <li>Angelina Jolie</li>
                      <li>Chris Evert</li>
                      <li>Julia Louis-Dreyfus</li>
                    </ul>
                  </p>
                  <div>
                    <h3 className="mt-2">Reading Material</h3>
                    <ol>
                      <li>
                        <a href="https://www.aboutgeneticcounselors.org/Resources-to-Help-You/Post/genetic-testing-led-to-chris-evert-discovering-her-cancer-diagnosis-heres-what-you-should-know-about-these-tests" target="_blank">
                          {" "}
                          Genetic testing led to Chris Evert discovering her
                          cancer diagnosis. Here’s what you should know about
                          these tests.
                        </a>
                      </li>

                      <li>
                        <a href="https://ascopost.com/issues/february-10-2015/dr-mary-claire-king-proposes-population-screening-in-all-young-women-for-brca-mutations/" target="_blank">
                          {" "}
                          Dr. Mary-Claire King Proposes Population Screening in
                          All Young Women for BRCA Mutations
                        </a>
                      </li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>

            {/*  */}
          </div>
        );
      })}
    </div>
  );
}
