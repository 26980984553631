import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../utils/api";

function LoginNew() {
    const navigate = useNavigate();
    const initialValues = { phone: "", email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const changeText = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const { phone, email, password } = formValues;
    const submitHandler = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        console.log('called');
        try {
          const response = await api.post('/user/login', formValues, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log('response', response.data);
          if (response.data.accessToken) {
            localStorage.setItem('isLoggedin', 'true');
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            toast.success('Loggedin successfully');
            navigate('/review');
            window.location.reload(false);
          } else {
            toast.error('Invalid Login');
          }
          console.log('resultAdminLogin', response.data.accessToken);
        } catch (error) {
          console.error(error);
          // Handle error here
        }
      };
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.password) {
            errors.password = "password is required";
        }
        return errors;
    };
    return (
        <div>
            <div className="pageBg">
                <div className="pageBanner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-center order-2 order-md-1">
                                <img
                                    className="img-fluid bannerLeftImage p-4 d-none d-md-block mx-auto"
                                    src="/assets/images/BloodTest-bro.png"
                                ></img>
                                <img
                                    className="img-fluid bannerLeftImage p-4 d-md-none mx-auto"
                                    src="/assets/images/Mobile/BloodTest-bro.png"
                                ></img>
                            </div>

                            <div className="col-md-6 order-1 order-md-2">
                                <div id="sign-in-button"></div>
                                <div className="glassBGContainer glassBG mt-4">
                                    <h2 className="c1"> Login to Review</h2>

                                    {/* form */}
                                    <form onSubmit={(e) => submitHandler(e)}>
                                        
                                        <div className="form-group pt-2 ">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={email}
                                                onChange={(e) => changeText(e)}
                                                className="form-control glassBG"
                                            />
                                        </div>
                                        {
                                            email ? "" : (<p className="text-danger">{formErrors.email}</p>)
                                        }
                                        <div className="form-group mt-2 pb-2">
                                            <label htmlFor="password">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                value={password}
                                                onChange={(e) => changeText(e)}
                                                className="form-control glassBG"
                                            />
                                        </div>
                                        {
                                            password ? "" : (<p className="text-danger">{formErrors.password}</p>)
                                        }
                                        <div className="form-group mt-2">
                                            <input
                                                type="submit"
                                                value="Login"
                                                className="btn btn-warning text-white fw-bold form-control"
                                            />
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginNew
