import React from "react";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./Login.css";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../firebase";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Navigate } from "react-router-dom";
import { APP_ENV } from "../../config";
import {
  GHI_WEB_CLIENT_NAME,
  GHI_WEB_CLIENT_PHONE,
  GHI_WEB_CLIENT_EMAIL,
  GHI_WEB_CLIENT_PASSWORD
} from "../../config/user-client-config";
import api from "../../utils/api";


class Login extends React.Component {
  //  navigate = useNavigate();
  // changed
  interval;

  constructor(props) {
    super(props);
    this.interval = function () { };
    this.state = {
      enteredMobile: "",
      isOtpSent: false,
      isValidMobile: false,
      isNotNumber: false,
      isValidOtp: false,
      isOtpTimerCompleted: false,
      isRegistered: true,
      mobile: "",
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
      digit6: "",
      inputValue: "",
      otp: [],
      isValid: true,
      minutes: 1,
      seconds: 25,
      onLogInCbk: props.onLogInCbk,
      phoneLoading: false,
      OTPLoading: false,
      smsNotSent: false,
    };
    props.onLoad(false);
  }

  // TODO
  // 1: HANDLE SMS NOT SENT ERROR MESSAGE WHICH IS SEEN SOMETIMES
  // 2: Fix handling the enter key press and form submission

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      // Handle the Enter key press event here
    }
  };

  handleChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  configureCaptcha = () => {
    auth.languageCode = "en";
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //this.onSignInSubmit();
          // console.log('recaptcha verified');
        },
      },
      auth
    );
  };

  onMobileInput = (event) => {
    const inputValue = event.target.value;
    if (!/$/.test(inputValue)) {
      this.setState({
        enteredMobile: "",
      });
    } else {
      this.setState({
        enteredMobile: event.target.value,
      });
    }

    // if (event.target.value[0] != ) {
    //   this.setState({ isValid: false });
    //   return;
    // }
    this.setState({ isRegistered: true });
    this.setState({ isValid: true });

    if (isNaN(Number(event.target.value))) {
      this.setState({ isValidMobile: false });
    } else {
      this.setState({ isValidMobile: true });
    }

    console.log(event);
    if (!isNaN(Number(event.target.value)) && event.target.value) {
      const { name, value } = event.target;
      this.setState({
        [name]: value,
      });
    }
  };

  onMobileInput1 = (value) => {
    console.log('>>>>>>>>> value:', value);
    const inputValue = value;
    this.setState({
      enteredMobile: value,
    });


    // if (event.target.value[0] != ) {
    //   this.setState({ isValid: false });
    //   return;
    // }
    this.setState({ isRegistered: true });
    this.setState({ isValid: true });

    if (isNaN(Number(value))) {
      this.setState({ isValidMobile: false });
    } else {
      this.setState({ isValidMobile: true });
    }


  };

  sendOtp = (e) => {
    console.log("sendOtp: ", e);
    e.preventDefault();
    this.setState({ phoneLoading: true });
    this.configureCaptcha();
    //const phoneNumber = "+91" + this.state.mobile;
    const phoneNumber = "+" + this.state.enteredMobile;
    console.log(phoneNumber);

    //  if (phoneNumber != ) {
    //     this.setState({ isRegistered: false });
    //     this.setState({ phoneLoading: false });
    //     return;
    //   }

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        window.confirmationResult = confirmationResult;
        this.setState({ isOtpSent: true });
        this.countdown();
        console.log("OTP has been sent");
        this.setState({ phoneLoading: false });
        // ...
      })
      .catch((error) => {
        this.setState({ isOtpSent: false });
        console.log("SMS not sent", error);
        this.setState({ smsNotSent: true });
        this.setState({ phoneLoading: false });
      });
  };

  onOTPInput = (event) => {

    if (isNaN(Number(event.target.value))) {
      this.setState({ isValidOtp: false });
      return;
    } else {
      const { name, value, id } = event.target;
      this.setState({
        [name]: value,
      });

      const otp = this.state.otp;
      otp[id] = value;

      this.setState({
        otp: otp,
      });
      if (otp.join("").trim().length === 6) {
        this.setState({ isValidOtp: true });
      }
    }
  };

  isUserClient() {
    return (APP_ENV === "client") ? true : false;
  }

  initLogin = async (user) => {
    const webclient_user = {
      name: GHI_WEB_CLIENT_NAME,
      phone: GHI_WEB_CLIENT_PHONE,
      email: GHI_WEB_CLIENT_EMAIL,
      password: GHI_WEB_CLIENT_PASSWORD
    };
    // Navigate( to='/#dashboard')
    window.localStorage.setItem("phoneNumber", user.phoneNumber);

    try {
      const response = await api.post('/user/login', webclient_user, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('response', response.data);
      if (response.data.accessToken) {
        window.localStorage.setItem("isLoggedin", "true");
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        toast.success('Loggedin successfully');
        window.location.reload(false);
      }
    } catch (error) {
      console.error(error);
      // Handle error here
    }
    if (this.isUserClient()) {
      // set order id
      const data = await api({
        url: `/zoho/allOrders`,
        method: "GET",
        params: { phoneNumber: user.phoneNumber }
      })
      const tempPD = data?.data[0];
      localStorage.setItem("orderId", tempPD.ID);
    }

    this.state.onLogInCbk();
    this.setState({ isValidUser: true });
    this.setState({ OTPLoading: false });
  };

  verifyOtp = (event) => {
    event.preventDefault();
    this.setState({ OTPLoading: true });
    console.log(this.state)
    const code = this.state.otp.join("");
    console.log(code);
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        this.initLogin(result.user);
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        this.setState({
          isValidUser: false,
        });
        this.setState({ OTPLoading: false });
        console.log(error);
        console.log("otp verification failed")
      });
  };

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log("next");
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      } else if (next == 6) {
        // document.getElementsByClassName('verify-otp')[0].focus();
      }
    }
  };

  countdown = () => {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.setState({ seconds: this.state.seconds - 1 });
      if (this.state.seconds - 1 < 0 && this.state.minutes == 0) {
        return;
      } else if (this.state.seconds - 1 < 0 && this.state.minutes > 0) {
        this.setState({
          minutes: 0,
          seconds: 59,
        });
      }
      if (this.state.minutes == 0 && this.state.seconds - 1 <= 0) {
        this.setState({ isOtpTimerCompleted: true });
        clearInterval(this.interval);
      }
    }, 1000);
  };

  resendOtp = (e) => {
    this.setState({ isOtpTimerCompleted: false, minutes: 1, seconds: 30 });
    this.sendOtp(e);
  };

  componentDidMount() {
    const isLoggedin = localStorage.getItem("isLoggedin");

    if (isLoggedin === "true") {
      window.location.href = "#/dashboard";
    }
  }

  componentDidMount() {
    // axios({
    //   url: `${API_URL}/api/zoho/allOrders`,
    //   method: "GET",
    //   params:{phone :window.localStorage.phone}
    // })
  }

  render() {
    return (
      <div className="pageBg">
        <div className="pageBanner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 text-center order-2 order-md-1">
                <img
                  className="img-fluid bannerLeftImage p-4 d-none d-md-block mx-auto"
                  src="/assets/images/BloodTest-bro.png"
                ></img>
                <img
                  className="img-fluid bannerLeftImage p-4 d-md-none mx-auto"
                  src="/assets/images/Mobile/BloodTest-bro.png"
                ></img>
              </div>

              <div className="col-md-6 order-1 order-md-2">
                {/* <h2 className="ff1 c1 fs34 text-center text-md-start">
                {" "}
                Get Health Insights Right
              </h2> */}

                <div id="sign-in-button"></div>
                <div className="glassBGContainer glassBG mt-4">
                  {/* Mobile number */}
                  <div
                    className=" flex-column justify-content-center"
                    style={{
                      display: !this.state.isOtpSent ? "flex" : "none",
                    }}
                  >
                    <h3 className="c1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        fill="currentColor"
                        className="bi bi-phone"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                    </h3>
                    <h3 className="c1"> Continue with Phone Number</h3>





                   
                      <PhoneInput
                        country={'in'}
                        value={this.state.enteredMobile}
                        onChange={this.onMobileInput1}
                        inputStyle={{
                          width: "370px",
                          height: "45px"
                        }}
                      />



                    <button
                      className="send-otp btn mt-4"
                      disabled={!this.state.isValidMobile}
                      onClick={(event) => {
                        this.setState({ OTPLoading: true });
                        console.log("verify number: ", this.state.enteredMobile);
                        api({
                          url: `/zoho/verifyNumber`,
                          method: "POST",
                          mode: "no-cors",
                          data: {
                            phone: "+" + this.state.enteredMobile,
                          },
                        })
                          .then((data) => {
                            this.setState({ OTPLoading: false });
                            console.log("otp response ");
                            console.log(data);
                            this.sendOtp(event);
                          })
                          .catch((error) => {
                            console.log("here is false:");
                            console.log(this.state.enteredMobile);
                            console.log(error?.response?.data?.verify);
                            if (!error?.response?.data?.verify) {
                              this.setState({ isRegistered: false });
                              this.setState({ OTPLoading: false });
                            }
                          });
                      }}
                    >
                      {/* <Spinner animation="border" size="sm" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-clock me-4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                      </svg>
                      Send OTP
                      <span className="ms-2">
                        {this.state.phoneLoading ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          ""
                        )}
                      </span>
                    </button>
                    <Alert
                      key="danger1"
                      variant="danger"
                      style={{ display: this.state.isValid ? "none" : "block" }}
                      className="alert-box"
                    >
                      Phone number is not Valid
                    </Alert>
                    <Alert
                      key="danger2"
                      variant="danger"
                      style={{
                        display: this.state.isRegistered ? "none" : "block",
                        height: "auto",
                      }}
                      className="alert-box"
                    >
                      <div>
                        <p>
                          There appears to be a problem with recognizing your
                          registered phone number. If you have not registered,
                          please visit{" "}
                          <a href="https://diagnostics.strandls.com/genomic-wellness/" target="_blank">
                            "https://diagnostics.strandls.com/genomic-wellness/"
                          </a>
                          .If you have already registered please do contact our
                          Customer Service at{" "}
                          <a href="mailto:hello@strandls.com" target="_blank">
                            "hello@strandls.com"
                          </a>
                          .
                        </p>
                      </div>
                    </Alert>
                    {/* <Alert
                      key="danger"
                      variant="danger"
                      style={{
                        display: !this.state.smsNotSent ? "none" : "block",
                      }}
                      className="alert-box"
                    >
                      SMS Not sent Please try again later!
                    </Alert> */}
                  </div>

                  {/* OTP */}
                  <div
                    className=" flex-column justify-content-center"
                    style={{
                      display: this.state.isOtpSent ? "flex" : "none",
                    }}
                  >
                    <h3 className="c1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="42"
                        fill="currentColor"
                        className="bi bi-phone"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                    </h3>
                    <h3 className="c1">Please enter the OTP</h3>

                    <form className="otp-box-container d-flex mt-4">
                      <input
                        type="text"
                        id="0"
                        name="digit1"
                        className="otp-input form-control"
                        onChange={this.onOTPInput}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChange('digit1', e)}
                        tabIndex="1"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        type="text"
                        id="1"
                        name="digit2"
                        className=" otp-input form-control"
                        onChange={this.onOTPInput}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChange('digit2', e)}
                        tabIndex="2"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        type="text"
                        id="2"
                        name="digit3"
                        className=" otp-input form-control"
                        onChange={this.onOTPInput}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChange('digit3', e)}
                        tabIndex="3"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        type="text"
                        id="3"
                        className=" otp-input form-control"
                        name="digit4"
                        onChange={this.onOTPInput}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChange('digit4', e)}
                        tabIndex="4"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        type="text"
                        id="4"
                        name="digit5"
                        className=" otp-input form-control"
                        onChange={this.onOTPInput}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChange('digit5', e)}
                        tabIndex="5"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                      <input
                        type="text"
                        id="5"
                        name="digit6"
                        className=" otp-input form-control"
                        onChange={this.onOTPInput}
                        onKeyPress={this.keyPressed}
                        // onChange={(e) => this.handleChange('digit6', e)}
                        tabIndex="6"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                      />
                    </form>

                    <button
                      className="verify-otp btn mt-4"
                      onClick={this.verifyOtp}
                      disabled={!this.state.isValidOtp}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-indent me-4"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 8a.5.5 0 0 1 .5-.5h6.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H3.5A.5.5 0 0 1 3 8Z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M12.5 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5Z"
                        />
                      </svg>
                      Verify and Proceed
                      <span className="ms-2">
                        {this.state.OTPLoading ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          ""
                        )}
                      </span>
                    </button>
                    <div className="d-flex justify-content-between">
                      <button
                        className="resend-otp-btn"
                        onClick={(event) => {
                          api({
                            url: `/zoho/verifyNumber`,
                            method: "POST",
                            mode: "no-cors",
                            data: {
                              phone: this.state.mobile,
                            },
                          }).then((data) => {
                            console.log("otp ");
                            this.resendOtp(event);
                          });
                        }}
                        style={{
                          visibility: this.state.isOtpTimerCompleted
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        Resend OTP
                      </button>
                      <p className="countdown-text">
                        Time Remaining: {this.state.minutes}:
                        {this.state.seconds}
                      </p>
                    </div>

                    {/* <Alert
                      key="danger"
                      variant="danger"
                      className="otp-alert"
                      style={{
                        display:
                          this.state.isValidOtp && this.state.isOtpSent
                            ? 'block'
                            : 'none',
                      }}
                    >
                      {' '}
                      Otp is Not Valid{' '}
                    </Alert> */}

                    {this.state.isValidUser && (
                      <Navigate
                        to="/dashboard"
                        state={{ phoneNumber: this.state.enteredMobile }}
                        replace={true}
                      />
                    )}
                  </div>
                  <p>
                    Note: Please enter the Phone Number that was given at the
                    time of sample collection.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;