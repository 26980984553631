import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalService from '../../services/ModalService';
import { useState, useEffect } from 'react';

import  './Modal.css';
import { ClassNames } from '@emotion/react';

const style = theme =>( {
  position: 'absolute',
  top: '50%',
  left: '88%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height:"100%",
  backgroundColor: 'white',
  p: 4,

border:"none",
boxShadow: 24,
modalStyle1:{
  position:'absolute',
  top:'10%',
  left:'10%',
  overflow:'scroll',
  height:'100%',
  display:'block'
  
}
}

);

export default function Modalreport(props) {
  const [open, setOpen] = React.useState(false);
  const [modal1, setModal1] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const matches = useMediaQuery('(max-width:1014px)');

  useEffect(() => {
   ModalService.on('openModal', ({ component, props }) => {
      console.log("modaldata",props,component);
      setOpen(true);
      setModal1({
        props,
        component,
        close: (value) => {
          setModal1({});
        },
        });
       });
  }, []
  );
  function hideModal() {
    setShow(false);
  }
  return (
    <div>
      <Button onClick={handleOpen}></Button>
      <Modal
        open={open}
        onHide={hideModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         className={props.modalStyle1} 
               >
         {/* <span>{`(max-width:1014px) matches: ${matches}`}</span>; */}
        
        <Box sx={style}>
     {/* <Image src="/close-modal"></Image> */}
            
          <Typography id="modal-modal-title" variant="h6" component="h2" className='modal-head' >
           {modal1?.component}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 , fontSize:"14px", fontFamily:"Open Sans"}} >
           {modal1.props}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}