import React from 'react';
import ScroolToTop from '../../helpers/ScroolToTopButton';
export default function Footer() {
  return (
    <div className='container'>
        <div className='row'>
            <div className="col-md-12 text-center pt-3 ">
                
               <p className='fs11 py-4'>Copyright @2023. Strand Life Sciences Pvt Ltd.</p>
                </div>
                <ScroolToTop />
        </div>
        
    </div>

    
  )
}
