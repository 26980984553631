import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import api from "../../utils/api";

export default class Consult extends React.Component {
  constructor(props) {
    super(props);
    props.onLoad(false)
    this.state = {
      name: "",
      nameError: false,
     mobile: "",
     mobileError: false,
      email: "",
      emailError: false,
      emailError2: false,
      message: "",
      messageError: false,
      formValid: false,
      submitLoading: false,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleBlur = this.handleBlur.bind(this);
  }

  isValidEmail(email) {
    return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
  }

  isValidcontact(mobile) {
    return /^[6-9]\d{0-9}$/.test(mobile);
  }

  handleBlur(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });

    if (value.length <= 0 && name == "name") {
      this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false });
    }

    if (value.length <= 0 && name == "email") {
      this.setState({ emailError: true });
      
    } else {
      this.setState({ emailError: false });
    }
    if(value.length <10 && name=="mobile"){
      this.setState({mobileError:true})
    }
    else{
      this.setState({mobileError:false})
    }
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    const {
      name,
      email,
      message,
      mobile,
      mobileError,
      nameError,
      emailError,
      emailError2,
      messageError,
    } = this.state;

    this.setState({ nameError: name ? false : true });
    this.setState({ messageError: message ? false : true });
    this.setState({ emailError: email ? false : true });
    this.setState({mobileError: mobile ? false : true})
    if (email && !emailError) {
      this.setState({ emailError2: this.isValidEmail(email) ? false : true });
    }
    if (mobile && !mobileError) {
      this.setState({ mobileError: this.isValidcontact(mobile) ? false : true });
    }



    if (
      name &&
      email &&
      message &&
      mobile&&
      !nameError &&
      !mobileError &&
      !emailError &&
      !emailError2 &&
      !messageError
    ) {
      this.setState({ formValid: true });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body:{
          name: this.state.name,
          email: this.state.email,
          mobile: this.state.mobile,
          message: this.state.message,
        },
      };

      api.post('/consult/create', requestOptions)
      .then((response) => {
        console.log(response.data);
        this.setState({ postId: response.data.id });
      })
      .catch((error) => {
        console.error(error);
      });
        window.location.href="#/thank-you"
    } else {
      this.setState({ formValid: false });
    }

    e.preventDefault();
  }

  render() {
    const {
      name,
      email,
      message,
      mobile,
      nameError,
      mobileError,
      emailError,
      emailError2,
      messageError,
      formValid,
    } = this.state;

    if (!formValid) {
      return (
        <>
          <Container>
          <Row>
          <Col>
            <h1 className="ff1 fs30 fw600 mt-4">  
            {/* <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#e14d45"
                    className="bi bi-fingerprint me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                    <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                    <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                    <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                    <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                  </svg>
                </span> */}
                Request Results Consultation</h1>
          </Col>         
        </Row>
            <Row className="align-items-center">
              <Col>
                <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 mx-auto mt-3 bg-light">
                  <div className="card-header bg-transparent border-0 text-center text-uppercase">
                    <h3>{this.props.title}</h3>
                  </div>
                  <div className="card-body">
                    <form
                      action="/"
                      onSubmit={(e) => this.handleSubmit(e)}
                      encType="multipart/form-data"
                      autoComplete="off"
                    >
                      <div className="form-group">
                        <label className="mb-0 fs14">
                          Your name<span className="text-danger">*</span>
                        </label>
                        <input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        {nameError ? (
                          <div className="alert alert-danger mt-2">
                            Name is a required field.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-0 mt-2 fs14">
                          Your email<span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        {emailError ? (
                          <div className="alert alert-danger mt-2">
                            Email is a required field.
                          </div>
                        ) : (
                          ""
                        )}

                        {emailError ? (
                          <div className="alert alert-danger mt-2">
                            Email invalid.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-0 mt-2 fs14">
                          Your contact number<span className="text-danger">*</span>
                        </label>
                        <input
                          name="mobile"
                          type="text"
                          className="form-control"
                          placeholder="Contact"
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          value={this.state.mobile}
                        />
                        {mobileError ? (
                          <div className="alert alert-danger mt-2">
                            Mobile number is a required field.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-0 mt-2 fs14">
                          Message<span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="message"
                          type="text"
                          className="form-control"
                          placeholder="Message"
                          value={this.state.message}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        {messageError ? (
                          <div className="alert alert-danger mt-2">
                            Message is a required field.
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <p className="text-center mb-0 mt-3">
                        <input
                          type="submit"
                          className="btn btn1 btn-lg w-100 text-uppercase"
                          value="Submit Now"
                        />
                         <span className="ms-2">
                  {this.state.submitLoading ? 
                <Spinner animation="border" role="status" size="sm"/>
                :''
                  }
                </span>
                      </p>
                    </form>
                  </div>
                </div>
              </Col>
              <Col className="mt-4 p-4">
                <h2 className="ff2 fw500">
                 For Consultation
                </h2>
                <hr />
                {/* <h2 className="ff2 fw500 fs18 mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-telephone"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                
                </h2> */}

                <h2 className="ff2 fw500 fs18">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>{" "}
                  hello@strandls.com
                </h2>

                {/* <h2 className="ff2 fw500 fs18">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-building"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                    <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                  </svg>
                 
                </h2> */}

                <div className="row mt-5"></div>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return (
        <div className="thankyou_details">
          {/* <div className="alert alert-success mt-3">
           <h1 className=""> Information sent successfully.</h1>
          </div> */}
        <a href="#/thank-you"></a>
         
        </div>
      );
    }
  }
}



