import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import "./dashboard.css";
import { Box, Modal, Typography } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    // border: "2px solid #FFFFFF",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    height: "60%",
    overflow: "auto"
};

function NotesAndLimitations(props) {
    const [open, setOpen] = useState(false);
    const [isReportReady, setIsReportReady] = useState(false);
    const [draftOrder, setDraftOrder] = useState(null);
    ///const draftOrder = props.draftOrder;

    useEffect(() => {
        setIsReportReady(props.isReportReady);
    }, [props.isReportReady]);

    useEffect(() => {
        setDraftOrder(props.draftOrder);
    }, [props.draftOrder]);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    function convertGeneSymbolsToItalic(inputString) {

        if (!inputString) {
            return "";
        }

        const geneSymbolRegex = /\b[A-Z]{2,}\d?\b/g;
        const outputString = inputString.replace(geneSymbolRegex, "<i>$&</i>");
        return outputString;
    }

    return (
        <Row>
            <Col>
                <div className="p-4 bdrNone">
                    <div className="fs14 lh12 fw500 py-1">
                        <p>
                            Note that this analysis does not cover Variants of Uncertain
                            Significance - those genomic variants that have not yet been
                            categorized by current science as harmful or benign.
                        </p>
                        <p>
                            Only variants classified as Pathogenic or Likely Pathogenic
                            per the American College of Medical Genetics guidelines for
                            variant classification are included in this report.
                        </p>
                        <p>
                            Also, modern genome sequencing processes - of the type used
                            here - can identify genomic variants that involve one genomic
                            character being changed to another with high confidence. Such
                            variants are also called Single Nucleotide Variants (SNVs).
                            These comprise a majority of all genomic variants.
                        </p>
                        <p>
                            However, there are also genomic variants, albeit fewer, where
                            several consecutive genomic characters are either deleted or
                            repeated multiple times. These are called Copy Number Variants
                            (CNVs). Careful analysis of sequencing data can identify some
                            CNVs. However, these may need to be confirmed using
                            alternative methods before we can be confident of their
                            presence.
                        </p>
                        <p>
                            If any CNVs are included in this report, we will explicitly
                            indicate whether you should consider confirmation through
                            alternative methods, or if we have already done that for you.
                        </p>
                        {/* {draftOrder?.Report_JSON?.report?.special_notes?.some(item => "cnv_status" in item) &&
        <p>
          In your case, the following CNVs of significance were found
          that have not been confirmed using alternative methods. Please
          discuss with your genetic counselor if you would like to
          request confirmation.
        </p>
      } */}
                        {/* <p>{draftOrder?.Report_JSON?.report?.special_notes[0]?.cnv_notes}</p> */}
                        {/* <p>{draftOrder?.Report_JSON?.report?.special_notes?.map((i) => (
        <>{i?.cnv_notes}<br/></>
      ))}</p> */}
                        {draftOrder?.Report_JSON?.report?.special_notes?.map((i) => (
                            <p style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: i?.cnv_status === "confirmed-yes" && convertGeneSymbolsToItalic(i?.cnv_notes) !== "" ? convertGeneSymbolsToItalic(i?.cnv_notes) : "" }}></p>

                            // <p dangerouslySetInnerHTML={{__html: i?.cnv_status === "confirmed-yes" && convertGeneSymbolsToItalic(i?.cnv_notes)}}></p>
                            // <p>{i?.cnv_status === "confirmed-yes" &&  convertGeneSymbolsToItalic(i?.cnv_notes)}</p>
                        ))}
                        {draftOrder?.Report_JSON?.report?.special_notes?.map((i) => (
                            <p style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: i?.cnv_status === "confirmed-no" && convertGeneSymbolsToItalic(i?.cnv_notes) !== "" ? convertGeneSymbolsToItalic(i?.cnv_notes) : "" }}></p>
                            // <p dangerouslySetInnerHTML={{__html: i?.cnv_status === "confirmed-no" && convertGeneSymbolsToItalic(i?.cnv_notes)}}></p>
                            // <p>{i?.cnv_status === "confirmed-no" && convertGeneSymbolsToItalic(i?.cnv_notes)}</p>
                        ))}
                        {draftOrder?.Report_JSON?.report?.special_notes?.map((i) => (
                            <p style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: i?.cnv_status === "not-confirmed" && convertGeneSymbolsToItalic(i?.cnv_notes) !== "" ? convertGeneSymbolsToItalic(i?.cnv_notes) : "" }}></p>
                            // <p dangerouslySetInnerHTML={{__html: i?.cnv_status === "not-confirmed" && convertGeneSymbolsToItalic(i?.cnv_notes)}}></p>
                            // <p>{i?.cnv_status === "not confirmed" && convertGeneSymbolsToItalic(i?.cnv_notes)}</p>
                        ))}


                        <p>
                            Further information on limitations please refer here{" "}
                            <img onClick={handleOpen} src="info-circle.svg" width={12} className="ms-2 info-img" />
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Limitation Details
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 12 }}>
                                        <p>
                                            As with any laboratory test, there is a small chance
                                            that this result may be inaccurate for a procedural
                                            reason, such as an error during specimen collection
                                            and labeling (incorrect patient identification), an
                                            error in processing, data collection, or
                                            interpretation. Currently available data indicates
                                            that technical error rate for analysis involving DNA
                                            tests is anywhere between 1-3%. Variants that have not
                                            been confirmed by an independent analysis could
                                            represent technical artifacts. However, our validation
                                            study (Document No. VR-017v1) showed 100% concordance
                                            between the results obtained by NGS data and Sanger
                                            sequencing (confirmation of the variant), when the
                                            supporting read fraction of the variant with at least
                                            20 reads was &gt;30%.{" "}
                                        </p>
                                        <p>
                                            Large insertions, deletions, duplications, inversions,
                                            repeat expansions and complex rearrangements cannot be
                                            characterized accurately by NGS as it uses short-read
                                            sequencing data. Such structural variants have a much
                                            higher false-positive and false-negative rate than
                                            seen for SNVs (single nucleotide variant). It is
                                            possible that the genomic region where a disease
                                            causing variation exists in the person being tested
                                            (which may impact the phenotype) was not captured
                                            using the current technologies and therefore was not
                                            detected.{" "}
                                        </p>
                                        <p>
                                            For an autosomal dominant condition, if the variant
                                            does not seem to be inherited from parents, it could
                                            be due to a de novo (new) event or due to a germline
                                            mosaicism in an unaffected parent. In case of germline
                                            mosaicism, there is a risk of the disease recurrence
                                            in the family. However, due to technical limitations
                                            of this test, germline mosaicism cannot be determined
                                            by this test. Additionally, it is possible that a
                                            particular genetic abnormality may not be recognized
                                            as the underlying cause of the genetic disorder due to
                                            incomplete scientific knowledge about the function of
                                            all genes in the human genome and the impact of
                                            variants on those genes.
                                        </p>
                                        <p>
                                            Not all variations detected may be listed in the
                                            report. Inclusion of variations is dependent upon our
                                            assessment of their significance. The quality of
                                            sequencing and coverage varies between regions.
                                            Specific genomic regions, such as homopolymers, tandem
                                            repeat sequence, GC rich regions, high sequence
                                            homology, etc. influence the quality of sequencing and
                                            coverage. This may result in an occasional error in
                                            sequence reads or lack of detection of a particular
                                            genetic lesion. Accurate interpretation of this report
                                            is dependent on detailed clinical history of the
                                            patient. In the event of unavailability of detailed
                                            clinical history, the lab cannot guarantee the
                                            accuracy of the interpretation. This report is
                                            strictly not a medical diagnostic report and shall not
                                            be construed as the medical certificate or medical
                                            laboratory report.
                                        </p>
                                    </Typography>
                                </Box>
                            </Modal>

                        </p>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default NotesAndLimitations;