import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import "./variant-status.css";
import ReportUtils from "../../utils/ReportUtils";
import NotesAndLimitations from "../notes-and-limitations/NotesAndLimitations";

export default function VariantStatus(props) {
  const draftOrder = props.draftOrder;
  const gene = props.gene;
  const [showModal, setShowModal] = useState(false);

  if (!draftOrder) {
    return null;
  }

  console.log("TEXT", props.text);

  const special_notes = draftOrder?.Report_JSON?.report?.special_notes;
  var cnvNotConfirmedGene;

  if (special_notes) {
    for (let i = 0; i < special_notes.length; i++) {
      if (special_notes[i].gene_symbol === gene && special_notes[i].cnv_status === ReportUtils.VARIANT_DETECTED.NOT_CONFIRMED) {
        cnvNotConfirmedGene = special_notes[i].gene_symbol;
      }

    }
  }

  return (
    <>
      <Container className="variant-status">

        {(!props.text || props.text === ReportUtils.VARIANT_DETECTED.YES) &&
          <>
            <Row>
              <Col className="status-yes">
                Yes
              </Col>
            </Row>
          </>
        }
        {props.text === ReportUtils.RELEVANT.NO &&
          <Row>
            <Col className="relevant-no">
              N/A
            </Col>
          </Row>
        }
        {props.text === ReportUtils.VARIANT_DETECTED.CONFIRMED_YES &&
          <>
            <Row>
              <Col className="status-yes">
                Yes
              </Col>
            </Row>
            <Row>
              <Col className="status-yes fs10">
                <Link onClick={() => setShowModal(true)}>See notes and limitations</Link>
              </Col>
            </Row>
          </>
        }
        {props.text === ReportUtils.VARIANT_DETECTED.NOT_CONFIRMED &&
          <>
            <Row>
              <Col className="status-not-confirmed fs10">
                <Link sx={{ color: '#ff0000' }} onClick={() => setShowModal(true)}>See notes and limitations</Link>
              </Col>
            </Row>
          </>
        }
        {props.text === ReportUtils.VARIANT_DETECTED.NO &&
          <>
            <Row>
              <Col className="status-no">
                No
              </Col>
            </Row>
          </>
        }
        {cnvNotConfirmedGene && cnvNotConfirmedGene === gene &&
          <>
            <Row>
              <Col className="status-not-confirmed fs10">
                <Link sx={{ color: '#ff0000' }} onClick={() => setShowModal(true)}>See notes and limitations</Link>
              </Col>
            </Row>
          </>
        }
      </Container>
      <Modal
        backdrop="true"
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">Notes and Limitations</Modal.Title>
        </Modal.Header>
        <Modal.Body
        >
          <NotesAndLimitations draftOrder={draftOrder} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );


}
