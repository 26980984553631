import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import api from '../../utils/api';


export default function MyAccount(props) {

  props.onLoad(true);

  const [allPost, setAllPost] = useState([]);
const params = useParams();
  const getData = async () => {
    const data = await api({
      url: `/user/admininfo/${params.id}`,
      method: "GET",
   
      // params: { phoneNumber: window.localStorage.phoneNumber }
    })
    const tempPD = data?.data[0];
  
    setAllPost(tempPD);
    console.log("data", data.data);
  };

  console.log("hello", allPost)

  useEffect(() => {
    getData();
  }, [])




  return (

    <div className="container-fluid">
      <div className='p-md-3 pt-3' >

        <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent" >

          <div className="row">
            <div className="col-md-12">

              <h1 className="c2 fs34 ff1 ms-0 pt-4">
                My Account Details
              </h1>
            </div>

            <Row className='mt-3'>

              <Col md={6}>
                <h2 className='ff2 fs20 mb-3 fw500'>Personal Details</h2>
                <hr />

                <>

                  <div className='d-flex justify-content-between'>
                    <h2 className='fs16 fw500 ff2'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person me-2" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                    </svg></span>ID</h2>
                    <h2 className='fs16 fw500 ff2'>{allPost?.ID}</h2>
                  </div>
                  <hr />
                  <div className='d-flex justify-content-between'>
                    <h2 className='fs16 fw500 ff2'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person me-2" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                    </svg></span>Name</h2>
                    <h2 className='fs16 fw500 ff2'>{allPost?.Name?.display_value}</h2>
                  </div>
                  <hr />
                  <div className='d-flex justify-content-between mt-2'>
                    <h2 className='fs16 fw500 ff2'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg></span>Email</h2>
                    <h2 className='fs16 fw500 ff2'>{allPost?.Email}</h2>
                  </div>
                  <hr />

                  <div className='d-flex justify-content-between mt-2'>
                    <h2 className='fs16 fw500 ff2'><span>
                      <i className="fa-solid fa-calendar-days me-2"></i>
                    </span>D.O.B</h2>
                    <h2 className='fs16 fw500 ff2'>{allPost?.Date_of_birth}</h2>
                  </div>

                  <hr />
                  <div className='d-flex justify-content-between mt-2'>
                    <h2 className='fs16 fw500 ff2'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone me-2" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg></span>Telephone</h2>
                    <h2 className='fs16 fw500 ff2'>{allPost?.Phone_Number}</h2>
                  </div>

                  <hr />
                  <div className='d-flex justify-content-between mt-2'>
                    <h2 className='fs16 fw500 ff2'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle me-2" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    </svg></span>Gender</h2>
                    <h2 className='fs16 fw500 ff2'>{allPost?.Gender}</h2>
                  </div>
                  <hr />

                </>

              </Col>


            </Row>



          </div>
        </div>



      </div>
    </div>
  )
}
