import ModalService from "../../services/ModalService.js";

let config = require("./curation.js");

const truncateLength = 100;

const getICardText = (str) => {
  if (str.length <= truncateLength) return str;
  return `${str.substring(0, truncateLength)}...`
}

const getCurationObject = (data) => {
    return {
        value: data['title'],
        diorderType: data['diorderType'],
        title: data['title'],
        associatedGene: data['associatedGene'],
        icard: getICardText(data['description']),
        gender: data['gender'], 
        inheritance: data['inheritance'],
        popupFunction: function openModal(event) {
          ModalService.open(
            data['title'],
            data['description']
          );
        },
      }
}

const getCurationData = (rptCategory) => {
  const curationData = config.panelGenes[rptCategory]
  return curationData.map(data => getCurationObject(data))
}

export default {
  getCurationData: getCurationData
}