import React, { useState, useEffect } from "react";
import "./Metabolism.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GET_SINGLE_REPORT_URL } from "../../config";
import GradingIcon from '@mui/icons-material/Grading';
import api from "../../utils/api";

export default function Metabolism(props) {
  props.onLoad(true);
  const naviage = useNavigate();
  const location = useLocation();



  const [allPost, setAllPost] = useState([]);
  const [orderData, setOrder] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [allData, setAllData] = useState([]);

  const getData = async () => {
    let res = await api.get("/adultOnset/allMetabolic");
    // let res = await result.json();
    setAllPost(res?.data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const [draftOrder, setDraftOrder] = useState(null);
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderIds = queryParameters.get("orderId")
    setOrderId(orderIds)
    console.log("cancerId", orderIds)
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });
    console.log("new log carpo", reports);
    // setDraftOrder(reports?.data[0]);
    console.log("draftOrderscarpo", draftOrder)
  }
  function onVeiwReortClick() {
    naviage(`/metabolic-report?orderId=${orderId}`);
  }

  return (
    <div className="innerWrapper container-fluid">
      <Row className="justify-content-between cancer-bread">
        <Col className="pt-2 bread-wid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadForMobile">
              <li className="breadcrumb-item">
                <a href={"#/dashboard" + location.search}>Dashboard</a>
              </li>
              <li className="breadcrumb-item marginTop">
                <a href={"#/dashboard" + location.search}>Adult Onset</a>
              </li>
              <li
                className="breadcrumb-item active bg1 marginTop"
                aria-current="page"
              >
                Metabolism
              </li>
            </ol>
          </nav>
        </Col>
        <Col className="btncol">
          <button
            disabled={!localStorage.getItem('reportReady') || localStorage.getItem('reportReady') == 0}
            className="btn7 float-end"
            onClick={onVeiwReortClick}
          >
            <GradingIcon />View Results
          </button>
        </Col>
        {/* start repo sec */}
      </Row>

      {allPost.map((item) => {
        return (
          <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
            {/* end report section */}

            <div className="d-flex flex-column mt-3">
              <Row>
                <Col className="d-flex">

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">{item.section1_title}</h2>
                </Col>
              </Row>

              <p className="fs18  aligned-p">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.section1_description,
                  }}
                />
              </p>
            </div>

            <div className="">
              <Row>
                <Col className="d-flex mt-3">

                  <h2 className=" ff1 fs30 fw600 mt-3 text-secondary">{item?.section2_title}</h2>
                </Col>
              </Row>

              <div className="d-flex flex-row mb-3 align-items-center w-100 ">
                {/* <div>
            <img
              className="m-2"
              src="/metaboli.png"
              className="cardiac-condition"
            />
          </div> */}

                <div className="ps-3">
                  <ul>
                    <li>{item?.section2_desc1}</li>

                    <li>{item?.section2_desc2}</li>

                    <li>{item?.section2_desc3}</li>

                    <li>{item?.section2_desc4}</li>

                    <li>{item?.section2_desc5}</li>
                    <li>{item?.section2_desc6}</li>

                    <li>{item?.section2_desc7}</li>

                    <li>{item?.section2_desc8}</li>

                    <li>{item?.section2_desc9}</li>

                    <li>{item?.section2_desc10}</li>

                    <li>{item?.section2_desc11}</li>

                    <li>{item?.section2_desc12}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <Row>
                <Col className="d-flex mt-3">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}
                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">Testing for Risk</h2>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p className="fs18 ">
                    A number of genes are known to carry variants that cause a
                    substantially increased risk on the above conditions. Our
                    test sequences these genes to identify if you have a risk
                    causing mutation.
                  </p>
                </Col>
              </Row>

              <div>
                <Row>
                  <Col className="d-flex">
                    {/* <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="#e14d45"
                        className="bi bi-fingerprint"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                        <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                        <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                        <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                        <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                      </svg>
                    </span> */}
                    <h2 className=" ff1 fs30 fw600 mt-3 text-secondary">If You Are Positive</h2>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="fs18 ">
                      You should work closely with your clinician to further
                      confirm, assess, and manage your risk.
                    </p>
                  </Col>
                </Row>
              </div>

              <div>
                <Row>
                  <Col className="d-flex">
                    {/* <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="#e14d45"
                        className="bi bi-fingerprint"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                        <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                        <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                        <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                        <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                      </svg>
                    </span> */}
                    <h2 className=" ff1 fs30 fw600 mt-3 text-secondary">If You Are Negative</h2>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="fs18 ">
                      Based on our current knowledge, you are not at increased
                      risk from the known genomic causes covered by this test.
                      However, scientific knowledge is advancing rapidly so
                      please watch your account for any updates as more
                      information becomes available.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>

            <Row>
              <Col className="d-flex mt-4">

                <h2 className="ff1 fs30 fw600 mt-3 text-secondary"> {item?.section3_title}</h2>
              </Col>
            </Row>

            <div>
              <div className="card d-md-flex card mb-3 bdr-rad16 bxShdow bdrNone p-5">
                <Row>
                  <Carousel>
                    <Carousel.Item>
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="p-md-5 carousel-cap">
                            <h4>
                              {" "}
                              Disorders of amino acid and peptide metabolism
                            </h4>
                            <p>
                              This is a group of diseases where the genes
                              involved in the absorption, transport and
                              breakdown of amino acids have altered/impacted
                              function. These problems cause a buildup of
                              harmful substances in your body that can lead to
                              serious, sometimes life-threatening, health
                              problems.
                              <h5 className="pt-1">
                                Example: Phenylketonuria (PKU)
                              </h5>
                              Although it is principally a childhood disorder,
                              in rare cases, the first signs of PKU may develop
                              in late adulthood resembling common neurological
                              diseases.
                              <h5 className="pt-1">
                                Symptoms of Phenylketonuria (PKU)
                              </h5>
                              <ul>
                                <li>Learning disabilities</li>
                                <li>Mental retardation</li>
                                <li>Tremors or jerky movemnets of limbs</li>
                                <li>Skin rash or eczema</li>
                                <li>Epilepsy</li>
                                <li>Hyperactivity</li>
                                <li>Behavioral issues</li>
                              </ul>
                            </p>
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div style={{ marginTop: "160px" }}>
                            <img
                              src="/assets/images/meta-carousel.png"
                              alt="Second slide"
                              className="img-fluid pt-5 metabolism-carousel"
                              width={500}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-center text-center">
                        <Col className="pt-5"></Col>
                      </Row>
                    </Carousel.Item>

                    <Carousel.Item>
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="p-md-5 carousel-cap">
                            <h4> Lysosomal Storage Disorders</h4>
                            <p>
                              Lysosomal storage diseases or disorders (LSDs) are
                              rare genetic conditions that cause a buildup of
                              toxic materials in your body's cells. People with
                              LSDs lack certain enzymes or a substance that
                              helps the enzyme work.
                              <li>
                                {" "}
                                Lysosomal storage diseases usually appear during
                                pregnancy or soon after birth. More rarely,
                                adults may develop LSDs.
                              </li>
                              <li>
                                People usually have more severe cases when an
                                LSD starts early and more mild cases when an LSD
                                starts later.
                              </li>
                              <li>
                                Researchers have found more than 50 lysosomal
                                storage diseases. And they continue to identify
                                more.
                              </li>
                            </p>
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div style={{ marginTop: "15px" }}>
                            <img
                              src="/assets/images/Gauchers.png"
                              className="metabolism-carousel img-fluid"
                              width={700}
                            />
                          </div>
                          <div>
                            <h5 className="pt-5">
                              Example: Gauchers Disease
                            </h5>
                            <p>
                              Gauchers Disease is an inherited genetic disorder
                              of metabolism in which a dangerous level of a
                              fatty substance called glucocerebroside collects
                              in the liver, spleen, bone marrow, lungs, and at
                              times in the brain. This is an example of
                              Liidoses.
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Carousel.Item>
                  </Carousel>
                </Row>
              </div>
            </div>

            <div className="mb-3 bdr-rad16 bdrNone ">
              <Row>
                <Col className="d-flex mt-4">

                  <h2 className="ff1 fs30 fw600 mt-3 text-secondary">
                    {" "}
                    Celebrities with an Inborn Error of Metabolism
                  </h2>
                </Col>
              </Row>

              <div className="">
                <Row>
                  <Col>
                    <p className="fs18">
                      <ul>
                        <li>
                          Howard Dell, a former actor, sportsman and Olympian,
                          was diagnosed with Wilson's Disease in 2006.
                        </li>
                        <li>
                          Akhenaten, a pharaoh of the eighteenth dynasty of
                          Egypt, is believed to have had homocystinuria.
                        </li>
                      </ul>
                    </p>
                    <div className="">
                      <h3 className="mt-4">Reading Material</h3>
                      <ol>
                        <li>
                          <a href="https://rarediseases.org/rare-diseases/wilson-disease/" target="_blank">
                            {" "}
                            https://rarediseases.org/rare-diseases/wilson-disease/
                          </a>
                        </li>

                        <li>
                          {" "}
                          <a href="https://www.webmd.com/children/what-are-lysosomal-storage-disorders#:~:text=Lysosomal%20storage%20disorders%20are%20a,the%20body%20can%20recycle%20them" target="_blank">
                            {" "}
                            https://www.webmd.com/children/what-are-lysosomal-storage-disorders#:~:text=Lysosomal%20storage%20disorders%20are%20a,the%20body%20can%20recycle%20them
                          </a>
                        </li>

                        <li>
                          <a href="https://www.webmd.com/a-to-z-guides/inherited-metabolic-disorder-types-and-treatments" target="_blank">
                            {" "}
                            https://www.webmd.com/a-to-z-guides/inherited-metabolic-disorder-types-and-treatments
                          </a>
                        </li>
                      </ol>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
