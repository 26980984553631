import { useEffect, useState } from "react";
import "./Pharmaco.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GradingIcon from '@mui/icons-material/Grading';
import { GET_SINGLE_REPORT_URL } from "../../config/index";
import api from "../../utils/api";

const GENE_ALLELES = {
  "CYP2D6": "*1, *2, *3, *4, *6, *7, *8, *9, *10, *11, *12, *15, *17, *19, *29, *35, *41",
  "CYP2C19": "*1, *2, *3, *4, *10",
  "CYP2C9": "*1, *2, *3, *4, *5, *6, *8, *11",
  "CYP3A5": "*3, *6, *7",
  "CYP4F2": "rs2108622",
  "DPYD": "*1, *13",
  "F5": "rs6025",
  "NUDT15": "rs116855232, *1, *2, *4",
  "SLCO1B1": "rs4149056",
  "TPMT": "*1, *2, *3A, *3C, *4"
};

export default function pharmaco(props) {
  props.onLoad(true);
  const location = useLocation();
  const naviage = useNavigate();


  const [allPost, setAllPost] = useState([]);
  const [orderData, setOrder] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [allData, setAllData] = useState([]);

  const getData = async () => {
    let res = await api.get("/pharmacogenomics/getall");
    // let res = await result.json();
    setAllPost(res?.data?.data);
  };

  useEffect(() => {
    getData();
  }, []);


  const [draftOrder, setDraftOrder] = useState(null);
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderIds = queryParameters.get("orderId")
    setOrderId(orderIds)
    console.log("cancerId", orderIds)
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });
    console.log("new log carpo", reports);
    // setDraftOrder(reports?.data[0]);
    console.log("draftOrderscarpo", draftOrder)
  }

  function onVeiwReortClick() {
    naviage(`/pharma-repo?orderId=${orderId}`);
  }



  return (
    <div className="innerWrapper container-fluid">
      <Row className="justify-content-between cancer-bread">
        <Col className="pt-2 bread-wid">
          <nav aria-label="breadcrumb breadForMobile">
            <ol className="breadcrumb breadForMobile">
              <li className="breadcrumb-item">
                <a href={"#/dashboard" + location.search}>Dashboard</a>
              </li>

              <li
                className="breadcrumb-item active bg1 marginTop"
                aria-current="page"
              >
                Pharmacogenomics
              </li>
            </ol>
          </nav>
        </Col>
        <Col className="btncol">
          <button
            disabled={!localStorage.getItem('reportReady') || localStorage.getItem('reportReady') == 0}
            className="btn7 float-end"
            onClick={onVeiwReortClick}
          >
            {<GradingIcon />}
            View Results
          </button>
        </Col>
      </Row>

      {allPost.map((item) => {
        return (
          <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
            <Row>
              <Col md={6}>
                <div className="d-flex mt-3">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}

                  <h2 className="ff2 fw600 mt-3 text-secondary ">{item.section1_title}</h2>
                </div>

                <div className="c2 fs18 ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section1_desc,
                    }}
                  />
                </div>
              </Col>

              <Col md={6} className="">
                <img
                  width={400}
                  className="img-fluid PharmaImageHero"
                  src="/assets/images/Pharmacogenomics.png"
                />{" "}
              </Col>

              {/* <p className="c2">Those planning to get married or start a family, or have children in the same life stage, would benefit from knowing if they are carriers of a genetic disease</p> */}
              <Row>
                <Col>
                  <div className="">
                    <h3 className=""> Featured Example</h3>
                    <p className="c2 fs18 ">
                      Codeine is an orally administered drug that is used to
                      relieve mild to moderately severe pain, and it belongs to
                      the drug class of opioid analgesics.
                    </p>
                    <p className="c2 fs18 ">
                      The enzyme CYP2D6 present in the liver breaks down
                      (metabolizes) codeine. The CYP2D6 enzyme converts codeine
                      into its active metabolite, morphine, which provides its
                      analgesic effect (painkiller).
                    </p>
                    <p className="c2 fs18 ">
                      In people who have two inactive copies of CYP2D6, codeine
                      will not be broken down, and hence pain relief may be
                      inadequate. People in this situation are referred to as
                      “poor metabolizers” (PMs), and have reduced blood morphine
                      levels.
                    </p>

                    <p className="c2 fs18">
                      In contrast, individuals who have more than two
                      “normal-function” copies of the CYP2D6 gene (“ultrarapid
                      metabolizers”) are able to metabolize codeine to morphine
                      more rapidly and more completely. As a result, even with
                      normal therapeutic doses of codeine, they may experience
                      the symptoms of morphine overdose.
                    </p>
                  </div>
                </Col>
              </Row>
            </Row>

            <div className="">
              <Row>
                <Col className="d-flex mt-4">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section2_title}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.section2_desc,
                      }}
                    />
                  </p>
                </Col>
              </Row>
              <Row className="mt-2" style={{ padding: "0px 20px" }}>
                <Col md={4}>
                  <div className="card blueBG bxShdow bdrNone p-2 ps-3 h-100 mt-1 justify-content-center">
                    <p className="fs18">
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.section2_subDesc1,
                        }}
                      />{" "}
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card blueBG bxShdow bdrNone p-2 ps-3 h-100 mt-1  justify-content-center">
                    <p className="fs18">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.section2_subDesc2,
                        }}
                      />
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card blueBG bxShdow bdrNone p-2 ps-3 h-100 mt-1 justify-content-center">
                    <p className="fs18">
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.section2_subDesc3,
                        }}
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="">
              <Row>
                <Col className="d-flex mt-5">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section3_title}</h2>
                </Col>
              </Row>
              <ul className="pharma-list">
                <li className="fs18 mb-3">{item.section3_fact1}</li>

                <li className="fs18 mb-3">{item.section3_fact2}</li>

                <li className="fs18 mb-3">{item.section3_fact3}</li>
              </ul>
            </div>

            <div className="">
              <Row>
                <Col className="d-flex mt-4">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section4_title}</h2>
                </Col>
              </Row>
              <div>
                <p className="c2 fs18 ">
                  Pharmacogenomics is known to play a role in the metabolism of
                  Oncology drugs, drugs involved in behavioral health,
                  cardiovascular drugs and drugs involved in pain management.
                </p>
              </div>

              {/* <img className="img-fluid" src="/pharma.png"></img> */}
            </div>
            <div className="">
              <Row>
                <Col className="d-flex mt-4">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}
                  <h2 className=" ff2 fw600 mt-3 text-secondary"> {item.section5_title}</h2>
                </Col>
              </Row>
              <div>
                <p className="c2 fs18 ">
                  Based on the genes that a person possesses, they are
                  classified, as in the table below, by their ability to break
                  down a specific drug.
                </p>
              </div>
              <ul className="unsorted-pharam">
                <li className="fs18 mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section5_desc1,
                    }}
                  />
                </li>

                <li className="fs18 mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section5_desc2,
                    }}
                  />
                </li>

                <li className="fs18 mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section5_desc3,
                    }}
                  />
                </li>

                <li className="fs18 mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section5_desc4,
                    }}
                  />{" "}
                </li>

                <li className="fs18 mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section5_desc5,
                    }}
                  />
                </li>

                <li className="fs18 mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.section5_desc6,
                    }}
                  />
                </li>
              </ul>
            </div>

            <div className="gene-table">
              <Row>
                <Col className="d-flex mt-4">
                  {/* <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#e14d45"
                      className="bi bi-fingerprint"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z" />
                      <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332c0 .409-.022.816-.066 1.221A.5.5 0 0 1 6 8.447c.04-.37.06-.742.06-1.115V7Zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8Zm-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329Z" />
                      <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334Zm.3 1.67a.5.5 0 0 1 .449.546 10.72 10.72 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.72 9.72 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z" />
                      <path d="M3.902 4.222a4.996 4.996 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 3.996 3.996 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556Zm6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705ZM3.68 5.842a.5.5 0 0 1 .422.568c-.029.192-.044.39-.044.59 0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.531 6.531 0 0 0 3.058 7c0-.25.019-.496.054-.736a.5.5 0 0 1 .568-.422Zm8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.51 10.51 0 0 0 .584-2.678.5.5 0 0 1 .54-.456Z" />
                      <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865Zm-.89 1.257a.5.5 0 0 1 .04.706A5.478 5.478 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346Zm12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49Z" />
                    </svg>
                  </span> */}
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section6_title}</h2>
                </Col>
              </Row>

              <div>
                <p className="c2 fs18 ">
                  The Table below describes some of the genes that code for the
                  various enzymes in the liver that help in breaking down drugs.
                  In addition it also calls out the various “alleles” or “gene
                  recipes” that are known to have documented outcomes in drug
                  metabolism. The genes tested and the variants reported in this
                  test account for the metabolism of over 107 known drugs.
                </p>
              </div>

              <Row>
                <Col className="">
                  <div className="card bxShdow bdrNone p-3">
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Gene</th>
                          <th>Alleles</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Object.keys(GENE_ALLELES).map(gene => {
                            return (
                              <tr>
                                <td className="italicGene">{gene}</td>
                                <td>{GENE_ALLELES[gene]}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="">
              <h3 className="mt-4">Reading Material</h3>
              <ol>
                <li>
                  <a href="https://www.theguardian.com/science/2022/mar/29/experts-push-for-genetic-testing-to-personalise-drug-prescriptions" target="_blank">
                    {" "}
                    https://www.theguardian.com/science/2022/mar/29/experts-push-for-genetic-testing-to-personalise-drug-prescriptions
                  </a>
                </li>

                <li>
                  {" "}
                  <a href="https://www.psychologytoday.com/us/blog/mind-matters-menninger/202101/what-is-pharmacogenetic-testing-and-who-should-get-it" target="_blank">
                    {" "}
                    https://www.psychologytoday.com/us/blog/mind-matters-menninger/202101/what-is-pharmacogenetic-testing-and-who-should-get-it
                  </a>
                </li>

                <li>
                  <a href="https://www.jax.org/news-and-insights/jax-blog/2022/february/when-is-pharmacogenomic-testing-useful-in-cancer-care" target="_blank">
                    {" "}
                    https://www.jax.org/news-and-insights/jax-blog/2022/february/when-is-pharmacogenomic-testing-useful-in-cancer-care
                  </a>
                </li>
              </ol>
            </div>
          </div>
        );
      })}
    </div>
  );
}
