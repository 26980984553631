import React, { useState, useEffect } from "react";
import "./Carrier.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { GET_SINGLE_REPORT_URL } from "../../config";
import useWindowPosition from "../../helpers/useWindowPosition";
import { useRef } from "react";
import GradingIcon from '@mui/icons-material/Grading';
import api from "../../utils/api";

export default function Carrier(props) {
  props.onLoad(true);
  const location = useLocation();
  const topRef = useRef();
  const naviage = useNavigate();
  const positions = useWindowPosition();



  const [allPost, setAllPost] = useState([]);
  const [orderData, setOrder] = useState([]);
  const [orderId, setOrderId] = useState();
  const [allData, setAllData] = useState([]);

  const getData = async () => {
    let res = await api.get("/carrierrisk/getall");
    // let res = await result.json();
    setAllPost(res?.data?.data);
  };

  useEffect(() => {
    getData();

    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);


  const [draftOrder, setDraftOrder] = useState(null);
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderIds = queryParameters.get("orderId")
    setOrderId(orderIds)
    console.log("carrierOrderId", orderIds)
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });
    console.log("new log carpo", reports);
    setDraftOrder(reports?.data[0]);
    console.log("draftOrderscarpo", draftOrder)
  }




  function onVeiwReortClick() {
    naviage(`/carrierriskrepo?orderId=${orderId}`);
  }
  return (
    <div className="innerWrapper container-fluid">

      <div ref={topRef}></div>
      <Row className="justify-content-between cancer-bread">
        <Col className="mt-2 bread-wid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadForMobile">
              <li className="breadcrumb-item">
                <a href={"#/dashboard" + location.search}>Dashboard</a>
              </li>
              <li
                className="breadcrumb-item active bg1 marginTop"
                aria-current="page"
              >
                Carrier Risk
              </li>
            </ol>
          </nav>
        </Col>
        <Col className="btncol">
          <button
            disabled={!localStorage.getItem('reportReady') || localStorage.getItem('reportReady') == 0}
            className="btn7 float-end"
            onClick={onVeiwReortClick}
          >
            <GradingIcon /> View Results
          </button>
        </Col>
      </Row>

      {allPost.map((item) => {
        return (
          <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">

            <div className=" ">
              <Row>
                <Col md={6} className="mt-3">
                  <div style={{ display: "flex" }}>
                    <h2 className="ff2 fw600 mt-3 text-secondary"> {item?.section1_title}</h2>
                  </div>

                  <p className="fs18">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.section1_description,
                      }}
                    />
                  </p>

                  <p className="fs18">
                    Those planning to get married or start a family, or have children, would benefit from knowing if they are carriers of a genetic disease
                  </p>
                </Col>
                <Col md={6} className=" carrierImage">
                  <img
                    width={400}
                    src="/assets/images/carrierrisk.jpg"
                    className="img-fluid bdr-rad16"
                  />
                </Col>
              </Row>
            </div>

            <div className="type-carrier mt-4">
              <Row>
                <Col className="d-flex">
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section3_title}</h2>
                </Col>
              </Row>

              <Row>
                <Col>
                  <p className="fs18">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.section3_desc1 }}
                    />
                  </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="img-fluid text-center">
                    <img
                      className="img-fluid p-5"
                      src="/assets/images/Autosomal.jpg"
                      alt=""
                      width={700}
                    />
                  </div>
                </Col>
              </Row>
              <div className="card bxShdow bdrNone"></div>
            </div>
            <div className="mt-4">
              <Row>
                <Col className="d-flex">
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section4_title}</h2>
                </Col>
              </Row>
              <p className="fs18">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.section4_description,
                  }}
                />
              </p>
            </div>

            <div className="p14 mt-4">
              <Row>
                <Col className="d-flex">
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item.section5_title}</h2>
                </Col>
              </Row>
              <div
                className=" table-carrier mt-2"
                style={{ padding: "0px 24px" }}
              >
                <div className="">
                  <Table responsive="sm">
                    <tbody>
                      <tr className="table-primary">
                        <td>Disease</td>
                        <td>Frequency per 1000,000</td>
                        <td>Total Numbers in India</td>
                        <td>Inheritance</td>
                      </tr>
                      <tr className="table-light">
                        <td>Hemophilia</td>
                        <td>8</td>
                        <td>9600</td>
                        <td>XR</td>
                      </tr>
                      <tr className="table-light">
                        <td>Muscular Dystrophy</td>
                        <td>29</td>
                        <td>348000</td>
                        <td>Most common in DMD which is XR</td>
                      </tr>
                      <tr className="table-light">
                        <td>Fragile X Syndrome</td>
                        <td>15</td>
                        <td>180000</td>
                        <td>XR</td>
                      </tr>
                      <tr className="table-light">
                        <td>Cystinuria</td>
                        <td>14</td>
                        <td>168000</td>
                        <td>AR</td>
                      </tr>
                      <tr className="table-light">
                        <td>Cystic Fibrosis</td>
                        <td>12</td>
                        <td>144000</td>
                        <td>AR</td>
                      </tr>
                      <tr className="table-light">
                        <td>Sickle Cell Anemia</td>
                        <td>11</td>
                        <td>132000</td>
                        <td>AR</td>
                      </tr>
                      <tr className="table-light">
                        <td>TOTAL</td>
                        <td>90</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>

            <div>
              <Row>
                <Col className="d-flex mt-4">
                  <h2 className="ff2  fw600 mt-3 text-secondary"> Testing for Carrier Risk</h2>
                </Col>
              </Row>

              <div>
                <Row>
                  <Col>
                    <p className="fs18 text-align">
                      An individual who is a carrier for a monogenic disease
                      will often have no indication that they are a carrier;
                      sequencing one’s genes is the only way to find out. If an
                      individual and their spouse is a carrier for an AR
                      disease, then each child of the couple has a 1 in 4 chance
                      of being affected. In addition, if a woman is a carrier
                      for an XR disease, then each male child has a 1 in 2
                      chance of being affected. Therefore, carrier testing can
                      help determine any risks to your children before you have
                      them.
                    </p>
                    <p className="fs18 text-align">
                      The American College of Gynaecologists recommends that all
                      patients who are considering pregnancy or are already
                      pregnant, should be offered carrier screening for a list
                      of monogenic diseases that include cystic fibrosis, spinal
                      muscular atrophy, thalassemias and hemoglobinopathies.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex">
                    <h2 className="ff2  fw600 mt-3 text-secondary"> If You Are Positive</h2>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="fs18">
                      If you are a woman and a carrier for an XR disease, or you
                      and your spouse are both carriers for an AR disease, then
                      talk to your gynecologist or a fetal medicine expert about
                      various options to manage your pregnancy.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col className="d-flex">
                    <h2 className="ff2  fw600 mt-3 text-secondary"> If You Are Negative</h2>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="fs18 text-align">
                      Based on our current knowledge, your children are not at
                      risk for the monogenic diseases covered by this test on
                      account of the variants covered by this test. However,
                      scientific knowledge is advancing rapidly so please watch
                      your account for any updates as more information becomes
                      available.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>

            <div>
              <Row>
                <Col className="d-flex mt-4">
                  <h2 className="ff2 fw600 mt-3 text-secondary"> {item?.section8_title}</h2>
                </Col>
              </Row>

              <Container className="mt-2">
                <Row>
                  <Carousel className="car-bg">
                    <Carousel.Item>
                      <Row>
                        <Col>
                          <img className="img-1" src="/carrierimg1.png" />
                        </Col>
                        <Col>
                          <h3>Prevalence of Sickle Cell Anemia</h3>
                          <p>
                            Certain ethnic groups in India have high carrier
                            frequency for AR Hemoglobinopathies such as Sickle
                            cell anemia (upto 20%)6 and Thalassemias (upto 17%
                          </p>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    {/* end item-1 */}

                    <Carousel.Item>
                      <Row className="align-items-center">
                        <Col>
                          <img
                            className="img-1"
                            src="/carrierimg2.png"
                            alt="Second slide"
                          />
                        </Col>

                        <Col>
                          <h3>Prevalence of Inborn Errors of Metabolism</h3>
                          <p>
                            5-15% of children born India annually are diagnosed
                            with Inborn Errors of Metabolism. These biochemical
                            disorders are AR and lead to physical and
                            intellectual disabilities, and even death.
                          </p>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    {/* end item-2 */}

                    <Carousel.Item>
                      <Row className="align-items-center">
                        <Col>
                          <img className="img-1" src="/carrierimg3.png" />
                        </Col>

                        <Col>
                          <h3>Prevalence of Bleeding and Clotting Disorders</h3>
                          <p>
                            1 in 1000 people globally are affected with bleeding
                            and clotting disorders such as Hemophilias A & B
                            (XR). India harbors 5% of all patients suffering
                            from these disorders. An example of such disorders
                            being Hemophilias A & B (XR)
                          </p>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    {/* end itsem-3 */}

                    <Carousel.Item>
                      <Row className="align-items-center">
                        <Col>
                          <img
                            className="img-1"
                            src="/carrierimg4.png"
                            alt="Second slide"
                          />
                        </Col>

                        <Col>
                          <h3>Prevalence of Spinal Muscular Atrophy (SMA)</h3>
                          <p>
                            Spinal muscular atrophy (SMA) is an AR, progressive,
                            neuromuscular disorder with a carrier frequency of 1
                            in 38 in India
                          </p>
                        </Col>
                      </Row>
                    </Carousel.Item>

                    <Carousel.Item>
                      <Row className="align-items-center">
                        <Col>
                          <img
                            className="img-1"
                            src="/carrierimg5.png"
                            alt="Second slide"
                          />
                        </Col>

                        <Col>
                          <h3>Prevalence of Spinal Muscular Atrophy (SMA)</h3>
                          <p>
                            Spinal muscular atrophy (SMA) is an AR, progressive,
                            neuromuscular disorder with a carrier frequency of 1
                            in 38 in India
                          </p>
                        </Col>
                      </Row>
                      {/* </div> */}
                    </Carousel.Item>
                  </Carousel>
                </Row>
              </Container>
              {/* end car sec */}
            </div>

            <Row>
              <Col className="d-flex mt-5">
                <h2 className="ff2  fw600 mt-3 text-secondary">
                  {" "}
                  Celebrities Who Are Carriers For A Monogenic Disease
                </h2>
              </Col>
            </Row>

            <div>
              <Row>
                <Col>
                  <p className="fs18 text-align">
                    <ul>
                      <li>Amitabh Bachchan</li>
                      <li>Jackie Shroff</li>
                      <li>Zinedine Zidane</li>
                    </ul>
                  </p>

                  <div>
                    <h4 className="mt-3">Reading Material</h4>
                    <ol>
                      <li>
                        <a href="https://www.easterneye.biz/bollywood-stars-break-stigma-and-speak-out-about-having-thalassaemia-minor/#:~:text=Whilst%20this%20may%20sound%20serious,issues%20other%20than%20moderate%20anaemia.&text=What%20do%20Amitabh%20Bachchan%20and,them%20also%20have%20thalassaemia%20minor%3F" target="_blank">
                          {" "}
                          Bollywood stars break stigma and speak out about
                          having thalassaemia minor
                        </a>
                      </li>

                      <li>
                        {" "}
                        <a href="https://www.acog.org/womens-health/faqs/carrier-screening#:~:text=Carrier%20screening%20is%20a%20type,child%20with%20a%20genetic%20disorder." target="_blank">
                          {" "}
                          ACOG Carrier Screening FAQs
                        </a>
                      </li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}

      {/* </div> */}

    </div>
  );
}
